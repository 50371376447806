@import 'components/global.scss';

.imageCropperWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100vh - 240px);
  // padding: 26px;
  padding-top: 12px;

}


.cropper_menu_secondary {
  width: 100%;
  display: flex;
  align-items: center;
  // border: 1px solid $c-gray-very-light;
  padding: auto;
  margin: 4px 0px 12px;
  button {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 8px auto;
    padding:  8px 16px;
    background-color: $c-gray-very-light;
    border-bottom: 3px solid  $c-gray-very-light;
    color: $c-text;
    &.selectedButton{
      background-color: $c-gray-very-light;
      border-bottom: 3px solid $c-blue;
    }
    &:hover{
      background-color: darken($c-gray-very-light,5%);
      border-bottom: 3px solid lighten($c-blue,10%);
    }

    svg {
      margin-right:8px;
    }
  }
}
  
.cropper_menu_main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: $scale;

  
  .cropper_menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .backBTN {
    margin-right: auto;
  }
  
}

.applyButton {
  display: flex;
  width: 100%;
  justify-content: right;
}

.infoTextLineBox {
  border-radius: $radius;
  border: 3px solid #f9f9f9;
  margin: 0px auto $scale auto;
  padding: 8px 12px;
  width: 90%;
.textLine {
  font-family: $font-default;
  font-size: 0.875em;
  text-align: center;
  color: $c-text;
  i {
      color: $c-purple;
  }
}
}

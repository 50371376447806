@import 'components/global.scss';

html {

  height: 100%;
  font-size: 62.5%;

}

#root {

  height: 100%;

}

body {

  color: $c-text;
  height: 100%;
  //font-size: 1.4rem;

  &.color {

    background-color: $c-purple;
    background-attachment: fixed;
    background-image: radial-gradient(closest-corner, #A992E6, #6363AC);

  }

  &.disableOverflow {

    overflow-y: hidden;

  }

  @media (min-width: $mobile){

    font-size: 1.6rem;

  }

  @media (min-width: 1200px){

    font-size: 1.6rem;

  }
  @media (max-width: 600px){

    font-size: 1.6rem;

  }
}

.mantine-ScrollArea-thumb {
  background-color: rgba(0, 0, 0, 0.15);
}

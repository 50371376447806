@import 'components/global.scss';

.unsplashSelector {
 width:100%;
 
}
.previewButton {
  background-color: $c-primary;
  color:white;
  padding:2px 8px;
  margin: 8px; 
  border-radius: $radius;
  &:disabled {
    background-color: pink;
  }
};
.getMoreImagesButton {
  background-color: $c-primary;
  color:white;
  padding:2px 8px;
  margin: 8px auto; 
  border-radius: $radius;
};
.getMoreImages {
  width: 100%;
  display: flex;
  
}
   
.myMasonryGrid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;


}


.myMasonryGrid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}
/* Style your items */
.myMasonryGrid_column > img {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
  width: 100%;
}

.previewImage {
  width: 100%;

}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between ;
  padding: $scale $scale1;
}

@import 'components/global.scss';

h1, h2, h3, h4, h5, h6 {

  font-family: $font-default;
  margin: 0;
  padding: 0;
}

h1, h2 {

  font-size: $scale;
  
}

h1 {
  
  font-weight: 600;
  font-size: $scale3;
  line-height: $scale3;
  
}

h2 {
  
  font-size: $scale2;
  line-height: $scale2;
  font-weight: 600;
  margin: $scale-2 0px;
}

h3 {
  font-size: $scale1;
  line-height: $scale1;
  font-weight: 600;
  margin: $scale-3 0px;
  
}

h4 {
  font-size: $scale;
  line-height: $scale;
  font-weight: 600;
  margin: $scale-4 0px;
  
}

p {
  
  line-height: $scale2;
  margin-bottom: $scale;
  
}

a {

  color: $c-blue;

}

p:last-child {

  margin-bottom: 0;

}

blockquote {

  font-style: italic;
  font-size: 1.1em;
  line-height: $scale2;

}

cite {

  display: block;
  font-style: normal;
  opacity: 0.7;
  margin: $scale1 0 0 0;

}

@import "components/global.scss";

.homepageFAQ {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6f6f6;
  padding: $scale2 $scale;
  flex-direction: row;
}

.componentWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 960px;

  @include tablet {
    flex-direction: column;
  }

  @include mobile {
    flex-direction: column;
  }

  .titleWrapper {
    max-width: 400px;
    display: flex;
    flex-direction: column;
    margin-bottom: $scale3;
  }

  .cardsWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    .card {
      border-top: 2px solid #313131;
      display: flex;
      padding: 16px 12px;
      width: 100%;
    }
  }
}

.titleWrapper {
  h2 {
    font-size: 56px;
    font-weight: 700;
    margin-bottom: 0px;
    color: #313131;
  }
  .sectionSubstitle {
    font-size: 30px;
    color: $c-text;
    font-family: $font-default;
    color: #313131;
    font-weight: 300;
  }
  button {
    border-bottom: 2px solid #313131;
  }

  @include tablet {
    h2 {
      font-size: 30px;
      line-height: 34px;
      color: #313131;
    }
    .sectionSubstitle {
      display: none;
    }
    button {
      display: none;
    }
  }

  @include mobile {
    h2 {
      font-size: 20px;
      line-height: 24px;
      color: #313131;
    }
    .sectionSubstitle {
      display: none;
    }
    button {
      display: none;
    }
  }
}

.contactUsBelow {
  display: none;
  
  @include tablet {
    margin: $scale2 0px;
    display: block;
    .sectionSubstitle {
      font-size: 20px;
      line-height: 24px;
      color: #313131;
    }
    button {
      border-bottom: 2px solid #313131;
    }
  }
  
  @include mobile {
    display: block;
    .sectionSubstitle {
      font-size: 20px;
      line-height: 24px;
      color: #313131;
    }
    button {
      border-bottom: 2px solid #313131;
    }
  }
}

.collapsableFAQ {
  flex-grow: 1;

  .mainRow {
    display: flex;
    flex-direction: row;
    align-items: start;
    cursor: pointer;
    .question {
      flex-grow: 1;
      color: #313131;
    }

    .toggleIcon {
      justify-content: center;
      align-items: center;
      border-radius: $radius;
      svg {
        stroke: #313131;
      }
      &:hover {
        background-color: #f2f2f2;
      }
    }
  }
}

.hidableContent {
  transition: all 0.3s ease-in-out;
  max-width: 92%;
  a {
    color: $c-text;
  }
}

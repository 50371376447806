@import "components/global.scss";

.themeConfigCard {
  width: 100%;
}

.formTitle {
  display: flex;
  align-items: center;
  margin: 0px 0px 4px 0px;
  background-color: #6363AC;
  border-radius: $radius;
  padding: 8px 16px;
  h3 {
    margin: 0px;
    color: #fff
  }
  svg {
    height: 16px;
    width: auto;
    margin-right: $scale;
    float: left;
  }
}
.formSubtitle {
  font-family: $font-default;
  font-size: 0.875em;
  margin: $scale2 0px 4px 10px;
}

.inputLabel {
  margin-bottom: 0px;
  font-size: 0.75em;
  color: $c-gray-light;
}

.themePicker {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border: 1px solid $c-gray-very-light;
  border-radius: $radius;
  border-radius: 8px;
  margin: $scale 0px $scale 0px;
  padding: $scale1 $scale2;
}

.pickerSubtitle {
  border-radius: $radius;
  background-color: $c-gray-very-light;
  padding: 2px;
  font-family: $font-default;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 0px;
  letter-spacing: 4px;
  margin-bottom: $scale;
}

.buttonWrapper {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: $scale2;
}

.backBTN {
  margin: $scale1 $scale4;
}

.bannerSelectOptions {
  display: flex;
  justify-content: space-evenly;
  button {
    border-radius: $radius;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 8px 4px;
    padding: $scale;
    height: 120px;
    width: 110px;
  }
  .BannerOption {
    svg {
      height: 70px;
      width: auto;
      rect {
        fill: $c-gray-dark;
      }
      path {
        fill: white;
      }
    }
    p {
      color: $c-text;
      font-size: 0.75em;
    }
  }
  .BannerOptionSelected {
    background-color: $c-gray-very-light;
    svg {
      path {
        fill: white;
      }
      rect {
        fill: $c-purple;
      }
      height: 70px;
      width: auto;
    }
    p {
      color: $c-text;
      font-size: 0.75em;
    }
  }
}

.styleOptionsMenu {
  width: 85%;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
}

.inButtonIcon {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.flexButton {
  display: flex;
}

.flexButtonIconSidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.iconSidebar {
  &:hover {
    background-color: white;
  }
}

.siderbarHeader {
  display: flex;
  height: 76px;
  justify-content: left;
  align-items: center;
  margin-bottom: $scale;
  // width:100%;
  background-color: #fbfbfb;
  padding: $scale $scale2;
  h2 {
    margin: 0px;
  }
}

.titleDecoration {
  border-bottom: 3px solid $c-blue;
  width: 96px;
}

.titleWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: baseline;
  svg {
    margin-left: auto;
  }
}

.iconWrapper {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  margin-bottom: 4px;
}

.mainViewWrapper {
  display: flex;
  flex-direction: column;
  padding: 0px $scale4;
  height: calc(100vh - (78px + 122.5px));
}
.mainViewWrapperWithBackButton {
  display: flex;
  flex-direction: column;
  padding: $scale $scale1;
  height: calc(100vh - (78px + 122.5px + 29px + 16px));
}

.backButtonWrapper {
  display: flex;
  align-items: center;
  margin: 0px 0px $scale $scale1;
  span {
    margin-left: $scale;
    font-size: 0.75em;
  }
}

.itemTitle {
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-bottom: 8px;
  color: $c-text;
  // background-color: #333;
  border: 2px solid $c-gray-dark;
  border-radius: $radius;
  padding: 4px 8px;

}

.headerSubTitle {
  font-size: 0.8em;
}

.clickToCopyText {
  border: 2px solid #f2f2f2;
  padding: 4px;
  border-radius: $radius;

  &:hover {
    //background-color: #f2f2f2;
    color: #cbe5c4;
    cursor: pointer;
    //background-color: $c-bg;
  }
  .tooltipNormal {
    background-color: pink;
  }
  .tooltipAfterClick {
    background-color: blue;
  }
}

.text_highlight {
  color: pink;
}

.textStyleSelector {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: $scale-2;
  margin: $scale-2;
  border-radius: $radius;
  background-color: $c-gray-very-light;
  .buttonStateActive {
    background-color: $c-blue;
    color: white;
  }
}

.inputSelect {
  // border: 1px solid #f2f2f2;
  border-radius: $radius;
  height: 34px;
  select {
    text-align: center;
    width: 100%;
    height: 100%;
    background-color: white;
    &:hover {
      background-color: #f2f2f2;
    }
  }
}

.bannerOptionsWrapper {
  padding: $scale-1;
  border: 2px solid #6363ac;
  border-radius: $radius;
  display: flex;
  flex-direction: column;
  align-items: center;
  h5 {
    margin-bottom: 8px;
  }
  margin-bottom: $scale;
}

.orIndicator {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 26px;
  width: 26px;
  border-radius: 15px;
  background-color: $c-gray-very-light;
  margin: 6px;
}


.imageSelect {
  border-radius: $radius;
  border: 2px solid #f2f2f2;
  height: 70px;
  width: 100px;
  padding: 12px 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 30px;
    width: auto;
  }
}

.ImageAndButtons { 
  display: flex;
  flex-direction: column;
  img {
    width: 100%;
    height: 20%;
    border-radius: $radius;
    border: 2px solid #f2f2f2;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $c-gray-very-light;
    margin-bottom: 8px;
    svg {
      height: 30px;
      width: auto;
    }
  }
}


.landingCoverButtons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  button{
    margin-bottom: 8px;
  }
}

.sectionIcon {
  margin-right: 8px;
}

.textOnImageStyling {
  h5 {
    margin-top: $scale;
  }
}

.helperBannerTypeSwitcher {
  display: flex;
  justify-content: center;
  margin-bottom: $scale;
  :first-child {
    border-top-left-radius: $radius;
    border-bottom-left-radius: $radius;
  }
  :nth-child(2) {
    border-top-right-radius: $radius;
    border-bottom-right-radius: $radius;
  }
  button {
    // font-family: $font-default;
    background-color: $c-gray-very-light;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    svg {
      margin-left: 4px;
    }
  }
  .activeButton {
    background-color: $c-purple;
    color: white;
  }
}
@import "components/global.scss";

.modalWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalMain {
  width: 814px;
  height: 548px;
  position: absolute;

  text-align: center;

  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-direction: column;
  justify-content: start;

  left: 0;
  right: 0;
  top: calc(50vh - 274px);
  // top: calc(50vh - 200px);
  // max-width: 100%;
  // margin: auto calc(50vw - 200px);

  background-color: $c-bg;
  padding: $scale2;
  border-radius: $radius;

  &:focus {
    outline: none;
  }
  .headerSection {
    h2 {
      margin-bottom: 0px;
    }
    p {
      margin-bottom: $scale;
    }
  }
}


.switchButton {
  height: 22px;
  width: 45px;
  background-color: $c-green;
  border-radius: 11px;
  padding: 3px;
  margin-bottom: $scale;
  .switchIndicator {
    height: 16px;
    width: 16px;
    border-radius: 10px;
    background-color: white;
    margin-left: 23px;
    transition: margin-left 0.2s ease-in-out;
  }
  &.switchButtonActive {
    background-color: lighten($c-gray-light, 20%);
    .switchIndicator {
      background-color: white;
      margin-left: 0px;
    }
  }
}
@import 'components/global.scss';

.modalWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalMain {
    background-color: white;
    width: 600px;
    height: 260px;
    position: absolute;

    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: calc(50vh - 130px);
    // top: calc(50vh - 200px);
    // max-width: 100%;
    // margin: auto calc(50vw - 200px);

    background-color: $c-bg;
    padding: $scale2;
    border-radius: $radius;

    button {
        margin: auto; 
    }

    &:focus {
        outline: none;
    }
}
@import "components/global.scss";

.jobInsightsSidebar {
  width: 100%;
  height: 100%;
  // overflow: scroll;

  // padding: $scale;
  .header {
    padding: $scale;
    margin: 0px;
    background-color: $c-blue;
    color: white;
    display: flex;
    flex-direction: row;
    h4 {
      margin-left: $scale;
    }
    @include largeDesktop {
    }
    @include desktop {
      display: none;
    }
  }
  .insightItem {
    margin-left: $scale;
    margin-right: $scale-4;
    border-left: 1px solid $c-gray-very-light;
    padding: $scale-2 0 $scale $scale;
    // background-color: lightblue;
    h5 {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 6px;
    }
    .styledP {
      font-size: 0.815em;
      border: 1px solid $c-gray-very-light;
      background-color: $c-gray-very-light;
      border-radius: 8px;
      padding: calc(#{$scale} - 6px);
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      font-family: $font-default;
    }

    .insightIndicator {
      display: block;
      position: relative;
      left: calc(-#{$scale} - 5px);
      top: calc(#{$scale} - 2px);
      height: 10px;
      width: 10px;
      border-radius: 4px;
      background-color: #d9d9d9;
    }
  }
}

.generalInsightCard {
  border: 1px solid #e0e0e0;
  border-radius: 4px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 4px 4px 8px;
  margin: 8px 0px;

  p {
    font-family: $font-default;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    margin: 0px;
    flex-grow: 1;
  }

  /* identical to box height */
  text-align: center;

  color: #595959;
}

.scoresIndicator {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  .currentIndicator {
    width: 7px;
    height: 7px;
  }
  .valuesWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    .aboveItem,
    .mainItem,
    .belowItem {
      margin: 0px;
      padding: 0px;
      font-family: $font-default;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;
      padding: 4px 0px;

      /* identical to box height */
      text-align: center;

      color: #595959;
    }
    .aboveItem,
    .belowItem {
      font-family: $font-default;
      font-style: normal;
      font-weight: 400;
      font-size: 10.5px;
      line-height: 9px;
      text-align: center;
    }
    .aboveItem {
      background: linear-gradient(
        360deg,
        #bdbdbd -11.11%,
        rgba(189, 189, 189, 0.2) 88.89%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    .belowItem {
      background: linear-gradient(
        180deg,
        #bdbdbd 0%,
        rgba(189, 189, 189, 0.2) 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
}


.tooltip {
  background-color: #333;
}

.insightTitle {
  display: flex;
  justify-content: space-between;
  align-items: left;
  svg {
    margin-left: 4px;
    margin-top: 1px;
  }
}
@import "components/global.scss";

.weGotYouCovered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f6f6f6;
  padding: $scale8 $scale;

  h2 {
    font-size: 56px;
    font-weight: 700;
    line-height: 60px;
    color: #313131;
  }
  .sectionSubstitle {
    font-size: 30px;
    line-height: normal;
    text-align: center;
    color: #313131;
    font-weight: 300;
  }

  @include tablet {
    h2 {
      font-size: 30px;
      line-height: 36px;
      text-align: center;
    }
    .sectionSubstitle {
      font-size: 18px;
      line-height: normal;
      text-align: center;

    }
  }
  @include mobile {
    h2 {
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #313131;
    }
    .sectionSubstitle {
      font-size: 16px;
      line-height: normal;
      text-align: center;
      color: #313131;
    }
  }

  .cardsWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 1180px;
  }
  .card {
    // width: 40vw;
    background-color: #ffec5c;
    margin: $scale;
    padding: $scale2;
    border-radius: 14px;
    width: 380px;
    color: #313131;
    // border: 3px solid #f6f6f6;
    &:hover {
      // border: 3px solid #595959;
      filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));
    }
    @include tablet {
      width: 80vw;
    }
    @include mobile {
      width: 90vw;
    }
    img {
      width: 40px;
      height: auto;
    }
  }
}

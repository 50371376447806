@import "components/global.scss";

.helperContextSwitcher {
  display: flex;
  justify-content: center;
  margin-bottom: $scale;
  :first-child {
    border-top-left-radius: $radius;
    border-bottom-left-radius: $radius;
  }
  :nth-child(2) {
    border-top-right-radius: $radius;
    border-bottom-right-radius: $radius;
  }
  button {
    // font-family: $font-default;
    background-color: $c-gray-very-light;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    svg {
      margin-left: 4px;
    }
  }
  .activeButton {
    background-color: $c-purple;
    color: white;
  }
}

@import "components/global.scss";

.helperBlockCard {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 18px;
  align-items: center;
  cursor: pointer;
}

.helperBlockCardImg {
  width: 46px;
}

.helperBlockCardtext {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  margin: 0px;
  padding: 0px $scale;
  & h4 {
    line-height: 32px;
  }
  & p {
    font-size: 1em;
  }
}

.cardContentWrapper {
  border-radius: 6px;
  border: 2px solid $c-border;
  & img {
    width: 46px;
  }
  display: flex;
  flex-direction: row;
  // height: 100px;
  padding: $scale-3;
  margin-left: $scale-3;
}
.checkboxCustom {
  div {
    input {
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.chekBoxWrapper {
  width: 40px;
  height: 100%;
}

.checkBox {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px;
  border: 2px solid $c-border;
  border-radius: 2px;
  width: 20px;
}


.delete_modal {
  position: absolute;
  top: calc(50vh - 200px);
  width: 400px;
  max-width: 100%;
  margin: auto calc(50vw - 200px); 
  background-color: $c-bg;
  padding: $scale2;
  border-radius: $radius;
}

.flexDivHorizontal{
  display: flex;
  flex-direction: row-reverse;
  button {
    margin-left: $scale;
  }
}
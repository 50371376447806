@import 'components/global.scss';

.imageSelector {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  margin: 12px 0px;
}

.img_container {
  position: relative;
  width: 100%;
  img {
    display: block;
    width: 100%;
    height: auto;
    border: 0;
  }
  
  .btn_container {
    display: none;
    justify-content: space-around;
  }
  
  &:hover .btn_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    position: absolute;
    padding: $scale-2 0px;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $c-dark;
    opacity: 0.65;
    color: white;
    border: none;
    button {
      // background-color: white;
      // padding:2px 8px;
      // margin: 8px; 
      // border-radius: $radius;
      svg {
        height: 12px;
        margin: auto;
      }
      
    }
  }
}

.unsplash_attribution {
  font-size: 0.625em;
  margin: 8px; 
  a {
    color: $c-text;
  }
}

@import "components/global.scss";

//https://github.com/atomiks/tippyjs-react/issues/52

.clickToCopyText {
  border: 2px solid #F2F2F2;
  padding: 4px;
  border-radius: $radius;
  
  &:hover {
    //background-color: #f2f2f2;
    color: #cbe5c4;
    cursor: pointer;
    //background-color: $c-bg;
  }
  .tooltipNormal {
    background-color: pink;
  }
  .tooltipAfterClick {
    background-color: blue;
  }
}

.text_highlight {
  color: pink;
}

@import "components/global.scss";

.homepageFAQ {
  position: relative;
  background-color: #ffec5c;
  overflow: hidden;
  padding: $scale8 $scale3;
  @include tablet {
    padding: $scale3 $scale1;
  }

  @include mobile {
    padding: $scale3 $scale1;
  }
}

.componentWrapper {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include tablet { 
    height: 260px;
    padding: 0px 36px ;
  }

  @include mobile {
  height: 260px;

  }
}

.backgroundImage {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  z-index: 0;
  @include tablet {
    width: 190vw;
  }

  @include mobile {
    width: 300vw;
  }
}

.shadowGradient {
  display: block;
  width: 100vw;
  height: auto;
}

.componentWrapper {
  max-width: 880px;
  margin: auto;
  h2 {
    font-size: 56px;
    font-weight: 700;
    line-height: 60px;
    margin-bottom: $scale-2;
    color: #313131;
    text-align: center;
  }
  .sectionSubstitle {
    font-size: 30px;
    color: $c-text;
    font-family: $font-default;
    color: #313131;
    font-weight: 300;
    text-align: center;
  }
  button {
    border-bottom: 2px solid #313131;
  }

  @include tablet {
    h2 {
      font-size: 40px;
      line-height: 44px;

    }
    .sectionSubstitle {
      font-size: 18px;

    }
  }

  @include mobile {
    h2 {
      font-size: 30px;
      line-height: 34px;

    }
    .sectionSubstitle {
      font-size: 16px;
    }
  }
}

.mainCTAButton {
  background-color: #313131;
  color: white;
  font-size: 30px;
  font-weight: 500;
  padding: 12px 12px;
  width: 294px;
  max-width: 100%;
  @include tablet {
    font-size: 20px;
    padding: 8px 8px;
    width: 244px;
  }
  @include mobile {
    font-size: 18px;
    width: 184px;
    padding: 8px 8px;
  }

  border-radius: 60px;
}

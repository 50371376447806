@import "components/global.scss";

.footer {
  background-color: #313131;
  height: 100%;
  padding: $scale6 $scale3;
  display: flex;
  justify-content: center;
  flex-direction: column;

  span,
  address {
    display: block;
    clear: both;
    opacity: 0.7;
    text-align: left;
    font-style: normal;
    font-size: 0.8em;
    text-align: center;
  }

  span {
    margin-bottom: $scale1;
  }

  nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    a {
      margin: 8px 0px;
    }
    margin-bottom: $scale;


    a {
      color: white;
      font-weight: 500;
      font-size: 0.9em;
      opacity: 0.7;
      text-decoration: none;
      margin-right: $scale1;
      text-transform: uppercase;
      transition: all 0.3s ease-in-out;

      &:hover {
        opacity: 1;
        color: $c-primary;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
      }
    }
  }
}

.logoWrapper {
  display: flex;
  justify-content: center;
}
.logoFooter {
  svg {
    width: 260px;
    max-width: 80vw;
    height: auto;
  }
}

@import "components/global.scss";

.main {
  height: calc(100vh - 250px);
  margin: 0px $scale3 0px $scale4;
  padding-top: $scale;
  
  p,
  li {
    font-size: 1em;
    line-height: $scale2;
  }
  li {
    margin-bottom: $scale;
    margin-left: $scale;
  }

  h3 {
    display:flex;
    margin-bottom: $scale;
  }
}


@import "components/global.scss";

.modalWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalMain {
  width: 814px;
  height: 548px;
  position: absolute;

  text-align: center;

  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-direction: column;
  justify-content: start;

  left: 0;
  right: 0;
  top: calc(50vh - 274px);
  // top: calc(50vh - 200px);
  // max-width: 100%;
  // margin: auto calc(50vw - 200px);

  background-color: $c-bg;
  padding: $scale2;
  border-radius: $radius;

  &:focus {
    outline: none;
  }
}

///// First screen - Select draft type

.cardWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.GenerateJobOption {
  background-color: $c-purple;
  width: 310px;
  padding: $scale2 $scale;
  margin: 8px;
  border-radius: $radius;
  h3 {
    color: #fff;
    text-align: left;
  }
  p {
    color: #fff;
    text-align: left;
  }
  button {
    margin: auto;
    width: 120px;
  }
  .recommendedLabel {
    margin-right: 140px;
    margin-top: 4px;
  }
  box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.06);
  svg {
    width: 78px;
    height: auto;
  }
}

.templateOptionsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.templateOption {
  display: flex;
  padding: $scale $scale;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  border-radius: $radius;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.06);
  width: 400px;
  // height: 40px;
  background-color: white;
  margin: 8px;
  p {
    line-height: normal;
  }
  button {
    width: 120px;
    margin: 0px auto;
  }
  h3 {
    margin-top: 0px;
  }

  .imageDescriptionWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .description {
      flex-grow: 1;
      margin-left: 12px;
    }

    .templateImg {
      background-color: #e7e7e7;
      padding: $scale $scale1;
      margin-right: 8px;
      border-radius: 4px;
      svg {
        height: 80px;
        width: auto;
      }
    }
  }
}

///// Second screen - Select draft type
///
///
///
///
.headerSection {
  p {
    margin-bottom: 4px;
  }
}

.iconAndTitleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: $scale-1;
  svg {
    width: 54px;
    height: 42px;
    path {
      fill: #313131;
    }
    margin-right: $scale-2;
  }
  h2 {
    margin-bottom: 0px;
  }
}
.adviceWrapper {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  width: 100%;
  svg {
    margin-top: 4px;
    width: 12px;
    height: 17px;
  }
  display: flex;
  flex-direction: row;
  .text {
    width: 306px;
    font-size: 12px;
    text-align: left;
    .tips {
      padding-left: 8px;
      border-left: 2px solid #313131;
    }
  }
}

.textareaWrapperMain {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  // background-color: #fff;
  padding: 0px 6px 6px 6px;
  margin-bottom: 8px;
  div {
    width: 100%;
    height: 312px;
    resize: none;
    font-family: "roboto";
    margin-bottom: 4px;
    padding: 8px;
    font-size: 14px;
    color: #313131;
    border: 2px dashed #313131;
    border-radius: $radius;
    background-color: #f3f3fa;
    text-align: left;
    overflow-y: scroll;
    ul {
      margin-left: $scale;
    }
    li {
      list-style-type: circle;
      list-style-position: outside;
      margin-bottom: 4px;
    }
    &:focus {
      outline: none;
    }
  }
  span {
    font-size: 8px;
    font-family: "roboto";
  }
}

.textareaWrapperAskForChanges {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  background-color: #fff;
  padding: 6px 6px;
  margin-bottom: 0px;
  textarea {
    width: 100%;
    height: 60px;
    resize: none;
    font-family: "roboto";
    margin-bottom: 4px;
    padding: 4px;
    font-size: 14px;
    color: #313131;
    border: 1px solid #f2f2f2;
    &:focus {
      outline: none;
      border: 1px solid #f2f2f2;
    }
  }
  span {
    font-size: 8px;
    font-family: "roboto";
  }
}

.generatJobPostingWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  .leftColInput {
    width: 340px;
    p {
      text-align: left;
      margin-bottom: 6px;
    }
  }
  .rightConfigAndSubmit {
    display: flex;
    flex-direction: column;
    margin-left: $scale;
    justify-content: space-between;
    height: 100%;
    width: 340px;
    p {
      text-align: left;
      margin-bottom: 6px;
    }
    .toggleMenuWrapper {
      p {
        font-size: 16px;
        margin-bottom: 6px;
      }
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: start;
    }

    .rowWrapper {
      width: 100%;
      display: flex;
      flex-direction: row;

      .firstCol,
      .secondCol {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
      }
      .firstCol {
        margin-right: $scale;
      }
    }
  }
}

.submitButtonsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: right;
  button {
    margin-left: 8px;
    svg {
      margin-left: 6px;
      height: 16px;
      width: auto;
    }
  }
}
.buttonGroup {
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: $scale;
  display: flex;
  flex-direction: row;
  button {
    background-color: #fff;
    padding: 8px 12px;
    font-size: 12px;
    font-family: "roboto";

    &:not(:first-child) {
      border-left: 2px solid #f2f2f2;
    }
  }
  .buttonActive {
    background-color: $c-purple;
    color: #fff;
    border-left: none;
  }
}

.settingLabel {
  padding-bottom: 8px;
  font-size: 12px;
}

.switchButton {
  height: 22px;
  width: 45px;
  background-color: $c-green;
  border-radius: 11px;
  padding: 3px;
  margin-bottom: $scale;
  .switchIndicator {
    height: 16px;
    width: 16px;
    border-radius: 10px;
    background-color: white;
    margin-left: 23px;
    transition: margin-left 0.2s ease-in-out;
  }
  &.switchButtonActive {
    background-color: lighten($c-gray-light, 20%);
    .switchIndicator {
      background-color: white;
      margin-left: 0px;
    }
  }
}

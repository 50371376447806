@import "components/global.scss";

.jobBasicInfoForm {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: $scale2 $scale4 $scale2 $scale4;

  .formStyle {
    width: 100%;
    flex-grow: 1;
    height: 0px;
    .scrollAreaHeight {
      padding-right: 15px;
    }
  }

  .divider {
    width: 100%;
    border-bottom: 1px solid $c-gray-very-light;
    margin-bottom: $scale;
  }
  h2 {
    margin-bottom: 4px;
  }
  h3 {
    color: lighten($c-text, 20%);
  }
  .headerSubTitle {
    font-size: 0.8em;
  }
  form {
    display: flex;
    flex-direction: column;

    .inputStyle {
      border: 1px solid #f2f2f2;
      border-radius: $radius;
      padding: 8px $scale2;
      background-color: #f2f2f2;
      margin-right: 20px;
    }
  }
}



.inputWrapper {
  display: flex;
  flex-direction: column;
  input,
  select {
    background-color: $c-gray-very-light;
    border: 1px solid darken($c-gray-very-light, 5%);
    margin: 4px 0px;
    padding: 12px 4px;
    width: 100%;
    color: $c-text;
    border-radius: $radius;
  }
  margin-bottom: $scale;
}

.tuggleEYEconWrapper {
  display: flex;
  align-items: center;
  svg {
    padding: 2px;
    margin: 4px 8px;
    &:hover {
      cursor: pointer;
    }
  }
}

.itemTitle {
  font-weight: 600;
  margin-bottom: 8px;
  color: $c-blue;
}

.itemSubTitle {
  font-size: 1em;
  font-weight: 400;
  color: $c-text;
}

.errorMessage {
  color: $c-red;
  font-size: 0.875em;
}

.requiredIndicator {
  color: $c-red;
}

.submitButton {
  margin-top: $scale;
}

.cardsWrapper {
  
}

.editorBasicInfoCard {
  display: flex;
  justify-content: center;
  border-radius: $radius;
  border: 1px solid $c-gray-very-light;
  margin: $scale 0px;
  box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.06);

}
@import "components/global.scss";

.themePreview {
  margin-bottom: auto;
  position: relative;
}

//NoFrame

.desktopPreview {
  width: 100%;
  // max-width: 780px;
  height: calc(100vh - 78px);
  border-radius: $radius;
}

.desktopInnerWrapper {
  height: calc(100vh - 78px);
}

.mobilePreview {
  height: calc(100vh - 70px);
  width: 100%;
}

// .mobileInnerWrapper {
//   // padding-right: $scale $scale2;
// }

@import "components/global.scss";

.themeConfigCard {
  // border-radius: $radius;
  // background: #fff;
  // box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.06);
  // Form
  select, input {
    background-color: $c-gray-very-light;
    border-radius: $radius;
    // width: 100%;
    width: 170px;
    max-width: 100%;
    padding: 8px $scale1;
    color: $c-text;
  }
}

.cardHeader {
  height: 100px;
  background-color: pink;
  width: 100%;
  border-bottom: 1px solid #f2f2f2 ;
}

.formTitle {
  display: flex;
  align-items: center;
  margin: 20px 0px;
  // border: 2px solid #595959;
  background-color: #6363AC;
  border-radius: $radius;
  padding: 8px 16px;
  h3 {
    margin: 0px;
    color: #fff
  }
  svg {
    height: 16px;
    width: auto;
    margin-right: $scale;
    float: left;
  }
}
.formSubtitle {
  font-family: $font-default;
  font-size: 0.875em;
  margin: $scale2 0px 4px 10px;
}

.inputLabel {
  margin-bottom: 0px;
  font-size:0.75em;
  color: #333;
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  margin: 0px 0px 10px 10px;
}



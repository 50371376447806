@import "components/global.scss";
.pdfOptions {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  button {
    border-radius: $radius;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 8px 4px;
    padding: $scale-2;
    height: 110px;
    width: 60px;
    &:hover {
      background-color: $c-gray-very-light;
    }
  }
  .BannerOption {
    svg {
      height: 50px;
      width: auto;
      rect {
        fill: $c-gray-dark;
      }
      path {
        fill: white;
      }
    }
    p {
      color: $c-text;
      font-size: 0.75em;
    }
  }
  .BannerOptionSelected {
    background-color: $c-gray-very-light;
    svg {
      path {
        fill: white;
      }
      rect {
        fill: $c-purple;
      }
      height: 50px;
      width: auto;
    }
    p {
      color: $c-text;
      font-size: 0.75em;
    }
  }
}

.imageSelector {
  border-radius: $radius;
  border: 2px solid $c-purple;
  padding: $scale;
  h5 {
    text-align: center;
    margin-bottom: 8px;
  }
  // background-color: $c-gray-very-light;
  .ImageAndButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
     img {
      border-radius: $radius;
      border: 2px solid #f2f2f2;
      max-height: 100px;
      max-width: 140px;
      padding: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $c-gray-very-light;
      svg {
        height: 30px;
        width: auto;
      }
    }
  }
  .imageSelect {
    border-radius: $radius;
    border: 2px solid #f2f2f2;
    height: 70px;
    width: 100px;
    padding: 12px 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      height: 30px;
      width: auto;
    }
  }

  .selectImageMessage {
    max-width: 50%;
    p {
      text-align: center;
      font-size: 0.875em;
    }
  }
}

.onlyAddButton {
  display: flex;
  width: 100%;
  justify-content: center;
}

.addButton {
  width : 116px;
  svg {
    margin-left: 4px;
  }
}
.removeButton, .cropButton {
  width : 116px;
  margin-top: 8px;
  svg {
    margin-left: 4px;
  }
}

.headerSubTitle {
  margin-top: 12px;
  padding: 16px 14px;
  font-size: 0.8em;
  background-color: lighten($c-orange, 26.5%);
  border-radius: $radius;
  text-align: center;
  b {
    color: darken($c-orange, 10%);
  }
}
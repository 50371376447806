@import "components/global.scss";


.headerSection {
  h2 {
    margin-bottom: 0px;
  }
  p {
    margin-bottom: $scale;
  }
}

///// First screen - Select draft type

.cardWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.GenerateJobOption {
  background-color: $c-purple;
  width: 310px;
  padding: $scale2 $scale;
  margin: 8px;
  border-radius: $radius;
  h3 {
    color: #fff;
    text-align: left;
  }
  p {
    color: #fff;
    text-align: left;
  }
  button {
    margin: auto;
    width: 120px;
  }
  .recommendedLabel {
    margin-right: 140px;
    margin-top: 4px;
  }
  box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.06);
  svg {
    width: 78px;
    height: auto;
  }
}

.templateOptionsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.templateOption {
  display: flex;
  padding: $scale $scale;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  border-radius: $radius;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.06);
  width: 400px;
  // height: 40px;
  background-color: white;
  margin: 8px;
  p {
    line-height: normal;
  }
  button {
    width: 120px;
    margin: 0px auto;
  }
  h3 {
    margin-top: 0px;
  }

  .imageDescriptionWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .description {
      flex-grow: 1;
      margin-left: 12px;
    }

    .templateImg {
      background-color: #e7e7e7;
      padding: $scale $scale1;
      margin-right: 8px;
      border-radius: 4px;
      svg {
        height: 80px;
        width: auto;
      }
    }
  }
}


@import "components/global.scss";



.dragAndDropList {
  display: flex;
  justify-content: center;
}

.block_order_tools {
  display: flex;
  height: 85px;
  margin: 8px;
  flex-direction: column;
  justify-content: space-around;
}

.block_order_toolshidden {
  display: none;
  
}

.card_container {
  padding: 1px;
  margin: 20px 10px 2px 2px;
  display: flex;
  justify-content: center;
  min-height: 85px;
  width: 100%;
  //border: 1px solid grey;
  //background-color: coral;
  @include largeDesktop {
    width: 560px;
    max-width: 100%;
    
  }
  @include desktop {
    min-width: 400px;
    width: calc(100vw / 3 + 50px);
    max-width: 460px;
  }
}

.dragHandle {
  height: 24px;
}

.blockWrapperSelected{
  background: #FFFFFF;
  box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.06);
  border-radius: $radius;
  width: 100%;
  border-left: 5px solid #fff;
  padding: $scale1;
  
}
.blockWrapperNotSelected{
  width: 100%;
  border-left: 5px solid #fff;
  padding: $scale1;
  background: #FFFFFF;
  cursor: pointer;
  // &:hover {
  //   border-left: 5px solid;
  // }
}

.blockCardActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 4px 20px;
}


.blockCardActionsHidden {
  //display: none;
  visibility: hidden;
}

.moveBlockOptionsHidden {
  visibility: hidden;
}

.moveBlockOptions {
  margin-left: auto;
  button {
    background-color: $c-gray-very-light;
    padding: 4px $scale1;
    margin: 4px;
    border-radius: $radius;
    align-items: center;
    color: $c-text;
  }
}

.btn {
  background-color: $c-gray-very-light;
  padding: 4px $scale1;
  margin: 4px;
  border-radius: $radius;
  align-items: center;
  color: $c-text;
}

.addBlockButton {
  margin-left: $scale2;
  height: 6px;
  visibility: hidden;
}

.description {
  border-left: 5px solid $c-green;
}

.yellow {
  border-left: 5px solid $c-orange;
}
.red {
  border-left: 5px solid $c-red;
}
.blue {
  border-left: 5px solid $c-blue;
}

h2 {
  margin-top: 0px;
}

.delete_modal {
  position: absolute;
  top: calc(50vh - 200px);
  width: 400px;
  max-width: 100%;
  margin: auto calc(50vw - 200px); 
  background-color: $c-bg;
  padding: $scale2;
  border-radius: $radius;
}

.flexDivHorizontal{
  display: flex;
  flex-direction: row-reverse;
  button {
    margin-left: $scale;
  }
}

.addBlockButtonButtom {
  display: flex;
  justify-content: center;
  margin-top: $scale5;
}

.sideBarIcons {
  img {
    width: $scale3;
    height: auto;
  }
}
.sideBarIconsSelected {
  background-color: #f2f2f2;
  // padding: 0;
  img {
    width: $scale3;
    height: auto;
  }
}

h2 {
  font-size: 28px;
}
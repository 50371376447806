@import 'components/global.scss';

.nav {

  height: $scale8;
  background-color: #FFEC5C;
  // background-image: radial-gradient(ellipse at top, #A992E6, #6363AC);

  >div {
    height: 100%;
  }

  nav {

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;

    a {

      color: #313131;
      margin-right: $scale;
      text-decoration: none;

      &:last-child {

        color: white;
        margin-right: 0;

      }
    }
  }
}

.logo {

  position: absolute;
  top: 50%;
  left: 0;
  margin: 0;
  transform: translateY(-50%);
  width: 288px;
  @media screen and (max-width: 580px) {
    margin-top: 2px;
    width: 228px;
  }
  @media screen and (max-width: 500px) {
    width: 188px;
  }
  @media screen and (max-width: 460px) {
    width: 148px;
  }
  @media screen and (max-width: 420px) {
    display: none;
  }
  height: auto;

}

.logoIcon {

  position: absolute;
  top: 50%;
  left: 0;
  margin: 0;
  transform: translateY(-50%);
  width: 288px;
  display: none;
  @media screen and (max-width: 580px) {
    display: none;
  }
  @media screen and (max-width: 500px) {
    display: none;
  }
  @media screen and (max-width: 460px) {
    display: none;
  }
  @media screen and (max-width: 420px) {
    width: 64px;
    display: block;
  }
  height: auto;

}

.transparent {

  background-image: none;
  background-color: #FFEC5C;

}

.mainCTA {
  background-color: #313131;
  padding: 12px 18px;
  border-radius: 20px;

  &:hover {
    background-color: lighten(#313131, 12%);
  }
}
@import "components/global.scss";

.text_highlight {
  color: #a3a3a3;
  font-style: italic;
}

.subtitle {
  display: flex;
  align-items: baseline;

  // margin: $scale1 0px;
  h3 {
    margin-bottom: 0px !important;
  }
  svg {
    height: 16px;
    width: auto;
    margin-right: $scale;
    float: left;
  }
  margin-bottom: $scale;
}

.red {
  path {
    fill: #d95565;
  }
}

.green {
  path {
    fill: #cbe5c4;
  }
}

.yellow {
  path {
    fill: #f7e26b
  }
}

.salmon {
  path {
    fill: #FDCBCB;
  }
}

.blue {
  path {
    fill: $c-blue;
  }
}
.lightPurple {
  path {
    fill: #C2CFFB
  }
}

// Editor
.sectionTitle {
}

.sectionSubtitle {
  color: lighten($c-text, 20%);
  font-size: 1em;
  margin-bottom: 4px;
}

// IMAGE BLOCK

.image_placeholder {
  img {
    height: 70px;
  }
}

.image_selected_preview {
  display: flex;
  align-items: center;
  img {
    width: 50%;
    padding-right: 5%;
  }
}

.img_options {
  margin: 0px auto;
  width: 16 0px;
  .format_image {
    background-color: blue;
  }
  .width_buttons {
    svg {
      height: 24px;
      width: auto;
    }
  }
  button {
    color: $c-text;
    background-color: $c-gray-very-light;

    &:hover {
      background-color: $c-darkpurple;
      color: white;
    }
    padding: 8px 8px 6px 9px;
    border-radius: $radius;
    margin: 4px 0px;
    margin-right: 8px;
    max-width: 100%;
  }
  //background-color: pink;
  display: flex;
  flex-direction: column;
}

.preview_and_editor {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  img {
  }
}

.emptyBlock {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  height: 110px;
  width: 100%;
  // border-left: 4px solid;
  border-radius: $radius;
  padding-left: $scale;
  margin: 0px;
  cursor: pointer;
  h2 {
    margin: 0px;
  }
}

.editorCloseButtonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

//Skill and requirements
.secondarySkilToggle {
  margin-bottom: $scale-2;
  margin: auto;
}

//benefits

.toggleFormatWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: auto;
  margin-bottom: $scale;
  button {
    display: flex;
    flex-direction: column;
  }
  button:nth-child(2) {
    margin-left: $scale2;
  }
}

.toggleButtonImage {
  margin-bottom: 8px;
  rect {
    fill: $c-gray-light;
  }
}
.toggleButtonImageActive {
  margin-bottom: 8px;
  rect {
    fill: $c-purple;
  }
}

//image
.croppingMessage {
}

@import "components/global.scss";

.saveStatusIndicator {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 110px;
  margin: 0px;
  color: lighten($c-text, 35%);

  .saveIcon {
    margin-left: 4px;
    @include largeDesktop {
      display: none;
    }
    @include desktop {
      display: none;
    }
    // @include tablet {
    //   display: block;
    // }
    // @include mobile {
    //   display: block;
    // }
  }

  .saveIconRotate {
    margin-left: 4px;
    animation: rotation 2s infinite linear;
    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
      }
    }
  }

  
  p {
    margin-bottom: 0px;
    font-size: 0.8em;
    margin-left: 8px;
    line-height: 1em;
  }
  // @include tablet {
  //   font-size: 0.8em;
  // }
  // @include mobile {
  //   font-size: 0.8em;
  // }
  .saveLabel {
    // @include tablet {
    //   display: none;
    // }
    // @include mobile {
    //   display: none;
    // }
  }
}

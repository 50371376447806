@import "./global/color";
@import "./global/scale";
@import "./global/utilities";
@import "./global/animation";

//FONTS
$font-default: "Roboto", "Helvetica Neue", Arial, sans-serif;

@mixin largeDesktop {
  @media screen and (min-width: 1281px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (max-width: 1280px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: 860px) and (min-width: 421px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 420px) and (min-width: 0px) {
    @content;
  }
}

@import "components/global.scss";

.sideBySideTextImage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: $scale10;
  padding-bottom: $scale6;
  background-color: #f6f6f6;
  @include tablet {
    padding-top: $scale8;
    padding-bottom: $scale3;
  }
  @include mobile {
    padding-top: $scale8;
    padding-bottom: $scale3;
  }
}

.sideBySideitemImgRight, .sideBySideitemImgLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: $scale6;

  .ImageWrapper {
    width: 30vw;
  }

  @include tablet {
    .ImageWrapper {
      margin: $scale3 0px;
      width: 60vw;
    }
  }
  @include mobile {
    .ImageWrapper {
      margin: $scale3 0px;
      width: 76vw;
    }
  }
}

.sideBySideitemImgRight {
@include tablet {
  flex-direction: column;
}
@include mobile {
  flex-direction: column;
}
}

.sideBySideitemImgLeft {
@include tablet {
  flex-direction: column-reverse;
}
@include mobile {
  flex-direction: column-reverse;
}
}


.textWrapper,
.ImageWrapper {
  &:first-child {
    margin-right: $scale6;
    @include tablet {
      margin-right: 0px;
    }
    @include mobile {
      margin-right: 0px;
    }
  }
}



.textWrapper {
  width: 514px;
  max-width: 50vw;
  @include tablet {
    max-width: 80vw;
  }
  @include mobile {
    max-width: 80vw;
  }
  font-family: $font-default;
  font-weight: 300;
  color: #313131;

  h2 {
    font-size: 56px;
    line-height: normal;
    margin-bottom: auto;
  }
  
  p {
    font-size: 30px;
    line-height: normal;
  }

  .tag {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 300;
  }

  @include tablet {
    h2 {
      font-size: 30px;
      line-height: normal;
      margin-bottom: auto;
    }
    
    p {
      font-size: 18px;
      line-height: normal;
    }
  
    .tag {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 300;
    }
  }
  @include mobile {
    h2 {
      font-size: 20px;
      line-height: normal;
      margin-bottom: auto;
    }
    
    p {
      font-size: 16px;
      line-height: normal;
    }
  
    .tag {
      text-transform: uppercase;
      font-size: 8px;
      font-weight: 300;
    }
  }
}

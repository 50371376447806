@import 'components/global.scss';

.imageSearch {

  width: 100%;
  background-color: $c-bg;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  border-radius: $radius ;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 38px auto;
  padding: 6px 24px;
  max-width: 420px;
}
.input {
  height: 100%;
  width: 100%;
  color: $c-text;
  background: none;
  border: none;
}
.magnifier {
  height: 24px;
  width: 24px !important;
}

.input::-webkit-input-placeholder {/* Chrome/Opera/Safari/Edge */
  color: $c-text;

}

.input::-ms-input-placeholder { /* Microsoft Edge */
  color: $c-text;

}

.input:-ms-input-placeholder {/* IE 10+ */
  color: $c-text;

}

.input::-moz-placeholder {/* Firefox 19+ */
	opacity: 1; /*Firefox by default has an opacity object that usually is ideal to reset so it matches webkit*/
  color: $c-text;

}

.input:-moz-placeholder {/* Firefox 18- */
	opacity: 1; /*Firefox by default has an opacity object that usually is ideal to reset so it matches webkit*/
  color: $c-text;

}

.input::placeholder {
  color: $c-text;

}
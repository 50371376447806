@import "components/global.scss";

.guarantee {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  color: #313131;
  .label {
    margin: 0px 8px;
    @include tablet {
      font-size: 0.875em;
    }
    @include mobile {
      font-size: 0.875em;
    }
  }
  svg {
    height: 24px;
    width: auto;
  }

}

@import "components/global.scss";

.mainCard {
  width: 68vw;

  @include desktop {
    width: 80vw;
  }
  
  @include tablet {
    width: 100%;
  }
  @include mobile {
    width: 100%;
  }
}

.buttonCancel {
  width: 100%;
  margin: auto;
  padding: 16px 16px;
  color: #313131;
  border-radius: 4px;
  font-family: $font-default;
  background-color: #f2f2f2;
  &:hover {
    background-color: darken(#f2f2f2, 5%);
  }
}

.delete_modal {
  position: absolute;
  top: calc(50vh - 200px);
  width: 540px;
  max-width: 100%;
  margin: auto calc(50vw - 270px);
  background-color: #fff;
  padding: $scale4 $scale2;
  border-radius: $radius;
}

.flexDivHorizontal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.disclaimer {
  font-size: 0.875em;
  margin-bottom: $scale;
  padding: $scale3;
  background-color: #f2f2f2b1;

  svg {
    margin-right: 8px;
  }

  a {
    color: $c-text;
  }

  margin-bottom: $scale4;
}

.bullets_style {
  list-style: circle;
  margin-left: $scale2;
}

.subscriptionCard {
  margin: auto;
  text-align: center;
  margin-bottom: $scale3;
}

.whatsIncludedContent {
  display: flex;
  flex-direction: row;
  @include largeDesktop {
    margin: 0px 10%;
  }
  @include tablet {
    flex-direction: column;
    margin: 0px 10%;
  }
  @include mobile {
    flex-direction: column
  }
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0px $scale;
  .includeItem {
    display: flex;
    flex-direction: row;
    margin-bottom: $scale;
    width: 45%;
    @include tablet {
      width: 90%;
    }
    @include mobile {
      width: 90%;
    }
    .includeCardTitle {
      text-align: left;
    }
    .includeCardDescription {
      text-align: left;
    }
    .includeCardImage {
      width: 50px;
      @include tablet {
        width: 38px;
      }
      @include mobile {
        width: 30px;
      }
      height: auto;
      margin-right: $scale;
    }
  }
}

.subscriptionActionButton {
  width: 100%;
  padding: $scale;
  display: flex;
  justify-content: center;
  align-items: center;
}

.middleSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 12px 0px;
}
.bottomSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  margin: $scale 0px;
  p {
    font-size: 14px;
    text-align: center;
  }
}
.priceLabel {
  padding: 8px 16px;
  .currency,
  .ammount {
    font-size: 30px;
    font-weight: 600;
  }
  .currencyLabel {
    font-size: 20px;
  }
  .forWhat {
    font-size: 16px;
  }
}

.plusSign {
  margin: $scale1;
}

.priceLabelSecondary {
  border: 2px dotted $c-text;
  padding: 8px 16px;
  border-radius: $radius;
  color: $c-text;
  .currency,
  .ammount {
    font-size: 20px;
    font-weight: 600;
    line-height: 0px;
  }
  .currencyLabel {
    font-size: 12px;
  }
  .forWhat {
    font-size: 10px;
  }
  .optionalLabel {
    font-size: 8px;
  }
}

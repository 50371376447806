@import "components/global.scss";

.editorLayout {
  main{
    padding-left: $scale7
  };
  min-height: 100%;
  min-width: 960px
}

@media (max-width: $mobile){
  .editorLayout {
    main{
    padding-left: 0px;
    min-width: 960px
  };
}
}

@import "components/global.scss";

.editorMain {
  height: calc(100vh - 78px);
  display: flex;
  background-color: #f2f2f2;
  justify-content: space-between;

  &__mainContent {
    background-color: lightblue;
    background-color: white;

    height: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    // padding: 0px $scale;
    @include largeDesktop {
    }
    @include desktop {
      justify-content: center;
    }
  }

  &__blockInfoAndConfig {
    display: flex;
    background-color: white;
    height: 100%;
    @include largeDesktop {
      // width: calc((100% - 178px) / 12 * 6);
    }
    @include desktop {
      width: calc((100% - 55px) / 12 * 6);
    }

    .editorContextCard {
      // flex-grow: 1;
      // min-width: 330px;
      width: 460px;
      // @include largeDesktop {
      // }
      // @include desktop {
      //   width: 330px;
      // }
      // width: 100%;
      // max-width: 460px;
      border-radius: $radius;
      margin: $scale2 $scale2;
      border: 1px solid $c-gray-very-light;
      
      background: #fff;
      box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.06);
      overflow-y: auto;
    }
  }

  &__tips {
    background-color: #fdf4ec;
    background-color: white;
    box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.06);

    height: 100%;
    display: none;
    @include largeDesktop {
      width: 178px;
      display: flex;
    }
    @include desktop {
      display: none;
    }
    
  }

  &__toggleBarRight {
    background-color: #76d3ce;
    background-color: white;

    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 12px;
    width: 85px;

    @include largeDesktop {
      display: none;
    }
    @include desktop {
      width: 80px;
    }

    .toggleBarButton {
      margin: 4px $scale;
      &__section {
        @include largeDesktop {
          display: none;
        }
        @include desktop {
          display: none;
        }
      }
    }

    .inButtonIcon {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }

    .iconWrapper {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 30px;
      margin-bottom: 4px;
    }
  }

  &__toggleBarLeft {
    background-color: lightgoldenrodyellow;
    background-color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: $scale2;
    button {
      margin-bottom: 4px;
    }
    @include largeDesktop {
      width: calc((100% - 178px) / 12 * 1);
    }
    @include desktop {
      display: none;
    }
  }

  &__toggleBarTop {
    background-color: lightgoldenrodyellow;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 8px;
    button {
      margin-bottom: 4px;
    }
    @include largeDesktop {
      display: none;
    }
    @include desktop {
    }
  }
}

.elemVisible {
  @include largeDesktop {
  }
  @include desktop {
    display: flex;
  }
}

.elemHidden {
  @include largeDesktop {
  }
  @include desktop {
  }
}

.sideBarIcons {
  img {
    width: $scale3;
    height: auto;
  }
}
.sideBarIconsSelected {
  background-color: #f2f2f2;
  // padding: 0;
  img {
    width: $scale3;
    height: auto;
  }
}

.noBlockSelectedMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20vh;
  img {
    width: 100px;
    height: auto;
    margin-bottom: $scale3;
  }
}

.drawerHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: $scale;
  h4 {
    color: white;
  }
  button:hover {
    background-color: lighten(#73B0F4, 10%);
  }
  .drawerHeaderIcon {
    height: 32px;
    width: auto;
  }
}

@import 'components/global.scss';

.collapseToggle {
   margin-bottom: $scale;
}

.titleRow {
   display: flex;
   align-items: baseline;
}

.tittleExpandButton {
   margin-left: auto;
   color:lighten($c-text, 25%);
}

.arrowButton {
   // width:14px;
   margin-right: 4px;
   svg {
      width: 16px;
      height: auto;
   }
}

.contentOfToggle {
   padding: $scale 0px;
}
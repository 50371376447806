@import "components/global.scss";

.mainCard {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contentWrapper {
  max-width: 90%;
  button {
    background-color: #313131;
    border-radius: 40px;
    &:hover {
      background-color: lighten(#313131, 20%);
    }
  }
  input {
    background-color: #f6f6f6;
  }
  a,
  .forgotPassword {
    color: $c-text-light;
  }
  .logo {
    svg {
      width: 170px;
      height: auto;
    }
  }
}

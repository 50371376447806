@import 'components/global.scss';

.main {
  height: 100vh;
  width: 100vw;
  position: relative;
}

.onboarding {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  // background-color: lighten($c-purple, 10%);
  background-color: #ffec5c;
}

.shadowGradient {
  display:block;
  position: absolute;
  bottom: 0;
  width: 100vw;
  height: auto;
}
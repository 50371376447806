@import "components/global.scss";

.formWrapper {
  width: 600px;
  @include tablet {
    width: 100%;
  }
  @include mobile {
    width: 100%;
  }
}

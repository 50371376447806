@import "components/global.scss";

.priceCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #f6f6f6;
  font-family: $font-default;

  h1 {
    font-size: 64px;
    line-height: 56px;
    text-align: center;
    color: #313131;
    margin: $scale 0px $scale 0px;
    @media screen and (max-width: 830px) {
      font-size: 50px;
      line-height: 52px;
      margin: $scale 0px $scale-2 0px;
    }
    @media screen and (max-width: 380px) {
      font-size: 42px;
      line-height: 44px;
      margin: $scale 0px 8px 0px;
    }
  }
}

.priceCardWrapper {
  width: 740px;
  height: 488px;
  background: url("./img/background_desktop.svg");

  @media screen and (max-width: 830px) {
    background: url("./img/background_mobile.svg");
    width: 375px;
    height: 660px;
  }
  @media screen and (max-width: 380px) {
    background: none;
  }

  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 0px $scale 0px;
}

////////// PLAN OPTION SELECTORS

.planOptions {
  display: flex;
  flex-direction: row;
  margin-bottom: $scale8;
}
.segmentedControlStyle {
  margin-right: 12px;
}
.segmentedControlLabelStyle {
  font-size: 14px;
  font-weight: 400;
  color: $c-text;
}

.selectStyleRoot {
  color: $c-text;
  width: 180px;
  border: none;
}
.selectStyleDropdown {
  border: none;
}
.selectStyleVariant {
  border: none;
}
.selectStyleSelected {
  background-color: #f2f2f2;
  color: $c-text;
}
.selectStyledropdownItems {
  color: $c-text;
}

.buttonActive {
  background-color: #fff;
  color: $c-text;
}

//////////////

.priceCardInner {
  background-color: white;
  width: 660px;
  height: 360px;
  border-radius: 25px;
  padding: 16px;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));

  display: grid;
  grid-template-areas:
    "main price-card-1"
    "main plus-sign"
    "main price-card-2";
  grid-template-columns: 4fr 3fr;
  grid-template-rows: 1fr 34px 1fr;

  @media screen and (max-width: 830px) {
    width: 310px;
    max-width: 100%;
    height: 596px;
    display: grid;
    grid-template-areas:
      "main"
      "price-card-1"
      "plus-sign"
      "price-card-2";
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 34px auto;
  }

  .mainCardContent {
    grid-area: main;
    line-height: 18px;
    h3 {
      font-size: 34px;
      margin-bottom: 10px;
      color: #313131;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      color: #313131;
    }
    button {
      width: 186px;
      height: 46px;
      border-radius: 30px;
      background-color: #313131;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-family: $font-default;
      font-size: 16px;
      font-weight: 500;
    }
  }
  .priceCard1 {
    grid-area: price-card-1;
    background-color: #f6f6f6;
    border: 3px solid #313131;
  }
  .plusSign {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-area: plus-sign;
    height: 34px;
  }
  .priceCard2 {
    grid-area: price-card-2;
    background-color: #f6f6f6;
    border: 3px solid #313131;
    border-style: dotted;
    .firstUserFree {
      display: block;
      position: absolute;
      bottom: 0px;
      right: 0px;
      width: 86px;
      height: auto;
    }
    span {
      font-family: $font-default;
      font-size: small;
    }
  }
  .mainCardContent,
  .priceCard1,
  .priceCard2 {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    padding: 16px 20px;
    .priceLabel {
      font-size: 28px;
      font-weight: 700;
      color: #6363ac;
      line-height: 28px;
      margin-bottom: 0px;
      span {
        font-size: 22px;
      }
    }
    .subText {
      font-weight: 500;
      font-size: 16px;
      color: #313131;
    }
  }
  .priceCard1,
  .priceCard2 {
    border-radius: $radius;
    padding: 16px 24px;
  }
}

.subtitle {
  font-size: 42px;
  margin-bottom: 10px;
  color: #313131;

  @media screen and (max-width: 980px) {
    font-size: 30px;
  }
}

.whatsIncludedContent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  padding-bottom: $scale8;

  @media screen and (max-width: 980px) {
    grid-template-columns: 1fr;
    justify-content: center;
  }
  .includeItem {
    padding-bottom: $scale2;
    width: 420px;
    @media screen and (min-width: 981px) {
      margin-left: 28px;
      &:nth-child(odd) {
        margin-left: auto;
      }
    }
    @media screen and (max-width: 980px) {
      margin: auto;
    }

    display: grid;
    grid-template-areas:
      "image title"
      "image description";
    @media screen and (max-width: 460px) {
      width: 340px;
      max-width: 100%;
    }

    @media screen and (max-width: 360px) {
      grid-template-areas:
        "image "
        "title"
        " description";
      width: 100%;
      padding: 12px;
    }

    .includeCardImage {
      grid-area: image;
      width: 100px;
      height: auto;
      margin: 28px;
      @media screen and (max-width: 980px) {
        width: 70px;
      }
      @media screen and (max-width: 460px) {
        width: 50px;
      }
      @media screen and (max-width: 360px) {
        margin: 0px auto 8px auto;
      }
    }
    .includeCardTitle {
      grid-area: title;
      font-size: 20px;
      font-weight: 700;
      line-height: 20px;
      margin: auto 0px 6px 0px;
      color: #313131;
      @media screen and (max-width: 360px) {
        text-align: center;
      }
    }
    .includeCardDescription {
      grid-area: description;
      color: #313131;
      font-size: 16px;
      font-weight: 500;
      @media screen and (max-width: 360px) {
        text-align: center;
      }
    }
  }
}

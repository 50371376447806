@use "sass:math";
@import 'components/global.scss';

fieldset {

  position: relative;
  clear: both;
  border: none;
  padding: 0;
  margin: 0 0 math.div($scale,2) 0;


  label {

    text-transform: none;

  }
}

.error {

  border: none;
  padding: 0 $scale $scale2;

  &:before {

    // psuedo border
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: $scale2;
    border-radius: $radius;
    border: 1px solid $c-red;
    background-color: lighten($c-red, 38%);

  }

  legend {

    color: $c-red;
    z-index: 2;
    padding-top: $scale;

  }
}

.errorMessage {

  top: 0;
  clear: both;
  margin-left: -1.1em;
  margin-right: -1.1em;
  margin-bottom: 0;

}

@import 'components/global.scss';

.jobIsUnpublished {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: $scale9;
    margin: auto;
    max-width: 720px;
    p {
        text-align: center;
    }
}
@import 'components/global.scss';

.avatarJC {
    width: 100px;
    height: 100px;
    border: 9px solid #FFEC5C;
    // background-color:  #FFEC5C;
    border-radius: 150px ;
    position: relative;
    img {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
    }

}
@import "components/global.scss";

.block_context_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1em;
  border-bottom: 1px solid #f2f2f2;
  height: 87px;
  padding: 0px 10px;
}
.title_decoration {
  width: 96px;
}

.block_name {
  margin-left: 34px;
  h3 {
    margin-top: 0px;
    margin-bottom: 2px;
  }
  @include largeDesktop {
  }
  @include desktop {
    margin-left: 16px;
  }
}

.block_subtitle {
  font-family: $font-default;
  font-style: normal;
  font-weight: 300;
  font-size: 0.5em;
  line-height: 0px;
  letter-spacing: 3px;
}

.image {
  display: block;
  height: 54px;
  img {
    height: 100%;
  };
}

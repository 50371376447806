@import "components/global.scss";

.formSubtitle {
  font-family: $font-default;
  font-size: 0.875em;
  margin: $scale2 0px $scale 10px;
}

.inputLabel {
  margin-bottom: 0px;
  font-size: 0.75em;
  color: $c-gray-light;
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  margin: 0px 0px $scale-1 0px;
}

.editBoxWrapper {
  // border: 2px solid #595959;

  border-radius: $radius;
  padding: $scale-1;
  //margin-left: 20px;
}

.inputStyle {
  border: 1px solid #f2f2f2;

  border-radius: $radius;
  padding: 8px $scale2;
  // background-color: #f2f2f2;
  // margin-right: 20px;
}

.inputMetrics {
  font-size: 0.8em;
}

.settingPreview {
  margin-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #f2f2f2;
  border-radius: $radius;
  padding: 8px;
}

.collapseWrapper {
  //padding-left: $scale;
}

.inputIcon {
  height: 24px;
  width: 24px;
}

.inputIconSmall {
  height: 16px;
  width: 16px;
  &:hover {
    cursor: pointer;
  }
}

.textInputWithIcon {
  display: flex;
  border: 1px solid #f2f2f2;

  border-radius: $radius;
  img {
    width: 24px;
    margin: 4px 6px 4px 6px;
  }
  input {
    text-align: center;
    width: 100%;
  }
}
.textInputWithIconAndPlusMinus {
  display: flex;
  align-items: center;
  border: 1px solid #f2f2f2;
  border-radius: $radius;
  .inputIcon {
    width: 24px;
    margin: 4px 10px 4px 6px;
  }
  input {
    text-align: center;
    width: 100%;
  }
}

.inputSelect {
  border: 1px solid #f2f2f2;
  border-radius: $radius;
  height: 34px;
  select {
    text-align: center;
    width: 100%;
    height: 100%;
    background-color: white;
    &:hover {
      background-color: #f2f2f2;
    }
  }
}

.radioButtons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #f2f2f2;
  border-radius: $radius;
  height: 34px;
  margin-bottom: $scale-1;
  select {
    text-align: center;
    width: 100%;
    height: 100%;
    background-color: white;
    &:hover {
      background-color: #f2f2f2;
    }
  }
  button {
    width: calc(100% / 4);
    height: 100%;
    background-color: $c-purple;
    border-radius: $radius;
  }
}

.rowWrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    margin: $scale-2;
  }
  p {
    font-size: 0.625em;
    font-family: $font-default;
    margin-left: $scale-2;
  }
}
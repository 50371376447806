@import "components/global.scss";
.generateLoadingScreen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  svg {
    width: 78px;
    height: auto;
    path {
      fill: #313131;
    }
  }
}

.textDisplayWrapper {
  display: grid;
  grid-template-rows: repeat(5, 1fr);
  gap: 6px;
  width: 100%;
  max-width: 400px; // Adjust as needed
  height: 120px;
  margin: $scale2 0px;
  font-family: "Roboto", sans-serif;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease;
  }

  .cell1,
  .cell5 {
    opacity: 0.3; // Lighter color for smaller cells
  }
  .cell2,
  .cell4 {
    opacity: 0.6; // Lighter color for smaller cells
  }

  .cell3 {
    // Central cell with normal styling
    opacity: 1;
    font-size: 1em;
    svg {
      height: 14px;
      width: auto;
      margin-right: 8px;
    }
  }

  .cell2,
  .cell4 {
    font-size: 0.8em;
  }

  .cell1,
  .cell5 {
    font-size: 0.5em;
  }
}

// .generateLoadingScreen {
//   width: 100%;
//   height: 100%;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   svg {
//     width: 78px;
//     height: auto;
//     path {
//       fill: #313131;
//     }
//   }
// }

// .textDisplayWrapper {
//   font-family: "roboto";
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   height: 200px;
//   .smallTop,
//   .smallBottom {
//     font-size: 0.7em;
//     color: lighten($c-text, 50%);
//     height: 22px;
//     padding: 2px;
// }

// .MediumTop,
// .MediumBottom {
//     height: 26px;
//     font-size: 0.825em;
//     color: lighten($c-text, 20%);
//     padding: 4px;
// }

// .Normal {
//     color: $c-text;
//     height: 30px;
//     padding: 6px;
//   }
// }

@use "sass:math";
@import "components/global.scss";

.export_modal {
  position: absolute;
  top: 90px;
  max-height: calc(100vh - 180px);
  max-width: 100%;
  overflow-y: scroll;
  min-height: 480px;
  max-width: 100%;
  margin: auto calc(50vw - 490px);
  background-color: $c-bg;
  padding: $scale2;
  border-radius: $radius;
  h2 {
    text-align: center;
  }
}

.mainContentWrapper {
  display: flex;
  flex-direction: row;
  h3 {
    background-color: darken($c-purple, 30%);
    border-radius: $radius;
    text-align: center;
    color: #ffffff;
    padding: 4px 12px;
    margin-left: $scale2;
    margin-right: $scale2;
    margin-bottom: $scale;
  }
}

.columnOne {
  width: 60%;
  border-right: 1px solid rgb(194, 194, 194);
  padding: 0px $scale;
}

.columnTwo {
  width: 40%;
  padding: 0px $scale;
}

.clickToCopyText {
  border: 2px solid #f2f2f2;
  padding: 4px;
  border-radius: $radius;
  width: 100px;
  &:hover {
    //background-color: #f2f2f2;
    color: #cbe5c4;
    cursor: pointer;
    //background-color: $c-bg;
  }
  .tooltipNormal {
    background-color: pink;
  }
  .tooltipAfterClick {
    background-color: blue;
  }
}

.copyLinkWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  .textareaCopyLink {
    width: 220px;
    height: 40px;
    border-radius: $radius;
    padding: 12px 10px;
    margin-right: 12px;
    color: $c-text;

    resize: none;
    white-space: pre;
    overflow-wrap: normal;
    overflow-x: hidden;
  }
}

.CopyButton {
  height: 30px;
  background-color: $c-purple;
  color: white;
  padding: 8px 12px;
  border-radius: $radius;
  font-size: 0.75em;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: lighten($c-purple, 10%);
  }
  svg {
    margin-left: 8px;
  }
}

.formWrapper {
  display: flex;
  flex-direction: column;
  button {
    width: 200px;
    align-self: center;
    margin-top: $scale;
  }
  label {
    font-family: $font-default;
    margin-bottom: 4px;
  }

  input {
    padding: 8px;
    border-radius: $radius;
  }
}

.textAreaInputGroupWrapper {
  background-color: #fff;
}

.CtaButtonCollaborate {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 60px;
  .styledButton {
    background-color: #8ec480;
    padding: 8px 30px;
    border-radius: $radius;
    color: #ffffff;
    font-size: 18px;
    text-decoration: none;
    margin-bottom: 6px;
  }
  .unstyledLink {
    text-align: center;
    color: #9c9c9c;
    font-size: 10px;
    text-decoration: none;
  }
}
.textAreaInputTop {
  resize: vertical;
  padding: 8px;
  font-family: $font-default;
  font-size: 14px;
  color: $c-text;
  height: 169px;
  width: 100%;
  overflow-y: hidden; /* Hide vertical scroll bar */
  min-height: 40px; /* Set a minimum height for the textarea */
  resize: none;
}

.textAreaInputBottom {
  resize: vertical;
  padding: 8px;
  font-family: $font-default;
  font-size: 14px;
  color: $c-text;
  height: 67px;
  width: 100%;
  overflow-y: hidden; /* Hide vertical scroll bar */
  min-height: 40px; /* Set a minimum height for the textarea */
  margin-bottom: 2px;
  resize: none;
}

.errorMessage {
  color: $c-red;
  font-size: 0.875em;
}

.submitButton {
  padding: 8px 16px;
  background-color: $c-purple;
  color: white;
  border-radius: $radius;
  &:disabled {
    background-color: #8ec480;
  }
}


.invitationSentLabel {
  background-color: lighten($c-green,30%);
  border-radius: $radius;
  padding: 20px 40px;
  text-align: center;
}
@use "sass:math";
@import "components/global.scss";

.editorNav {
  width: 100%;
  height: 78px;
  background-color: white;
  border-bottom: 1px solid $c-border;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__leftItems {
    // background-color: aqua;
    display: flex;
    align-items: center;
    margin-left: $scale3;

    .jobName {
      padding: 0px $scale;
      margin-left: $scale;
      margin-right: $scale;
      color: $c-text;
      font-size: 0.8em;
      width: auto;
      max-width: 180px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

  }

  &__centerItems {
    // background-color: coral;
    display: flex;
    align-items: center;
    justify-self: center;
  }

  &__rightItems {
    // background-color: darkred;
    display: flex;
    align-items: center;
    margin-right: $scale3;
    button {
      margin-left: 8px;
      svg {
        margin-left: 4px;
      }
    }
    @include largeDesktop {
    }
    @include desktop {
      margin-right: $scale;
    }
  }
}

.btn {
  display: flex;
  align-items: center;
  border-radius: $radius;
  background-color: $c-gray-very-light;
  padding: 2px 8px;
  color: $c-text;
  margin: 0px $scale;
}

.jobName {
  padding: 2px $scale1;
  color: $c-text;
  font-size: 0.8em;
  width: 180px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.steps {
  padding: 2px $scale1;
  color: $c-text;
  margin: 0px math.div($scale,2);
}

@media (max-width: $mobile) {
  .jobName {
    color: pink;
  }

  .editorNav {
    height: $scale6;
    padding-left: $scale3;
  }

  .hideOnMobile {
    display: none;
  }
  .rightItems {
    margin-right: $scale3;
    padding-left: 0px;
  }
}

.iconStyle {
  margin-left: 4px;
}

.stepIndicator{
  background-color: $c-text;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 12px;
  margin-right: 6px;
  font-size: 10px;
}

.guestEditLabel {
  font-size: 0.875em;
  text-transform: uppercase;
}
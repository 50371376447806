@import "components/global.scss";

.auth {
  header + section > button {
    display: block;
    margin: -$scale auto 0;
  }

  form {
    clear: both;
    margin-bottom: $scale1;

    span {
      // display: block;
      width: 100%;
      text-align: center;
      // margin-top: $scale1;
    }
  }
}




.pageWrapper {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  @include tablet {
    flex-direction: column-reverse;
  }
  @include mobile {
    flex-direction: column-reverse;
  }
  .featureHighlight,
  .main {
    width: 50%;
    padding: $scale4;
    min-height: calc(100vh - 291px);
    @include tablet {
      width: 100%;
      min-height: 400px;
    }
    @include mobile {
      width: 100%;
      min-height: 400px;
    }
    .pop {
      height: 100%;
    }
  }

  .featureHighlight {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fced74;
  }
}

.quoteWrapper {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: $scale6;
  @include tablet {
    padding: $scale2;
  }
  @include mobile {
    padding: 0px;
  }
  .quote {
    font-family: $font-default;
    font-size: 1.3em;
    text-align: center;
    color: #313131;
  }
  .name {
    color: #313131;
    margin-bottom: 12px;
  }
  .company {
    color: #313131;
    margin: 0px;
  }
  svg {
    width: 144px;
    path {fill : #313131};
  }
}

@import "components/global.scss";

.statsCardStyle {
  background-color: $c-gray-very-light;
  color: #c9c9c9;
  div {
    color: #cccccc;
    svg {
      color: #cccccc;
    }
  }
}

.noJobMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: $scale $scale4;
  border-radius: 4px;
  padding: $scale;
  background-color: #f3f3fa;
}

.searchBarAndCTAWrapper {
  display: flex;
  justify-content: space-between;
  @include tablet {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
  }
  @include mobile {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
  }
}

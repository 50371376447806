@import 'components/global.scss';

.createNewJobModal {

   
   
   
}

.delete_modal {
   background-color: white;
   position: absolute;
   top: calc(50vh - 200px);
   width: 400px;
   max-width: 100%;
   margin: auto calc(50vw - 200px); 
   padding: $scale2;
   border-radius: $radius;
}

.flexDivHorizontal {
   display: flex;
   flex-direction: row-reverse;
}

.addBlockButtonButtom {
   display: flex;
   justify-content: center;
   margin-top: $scale5;
}

.buttonsStyle {
   margin-left: $scale;
}

.buttonsIconStyle {
   margin-left: 4px;
}

.formStyle {
   display: flex;
   flex-direction: column;
}


.inputWrapper {
    input, select {
       background-color: $c-gray-very-light;
       margin: 4px 0px ;
       padding: 12px 4px;
       width: 100%;
       border-radius: $radius;
       color: $c-text;
    }
    margin-bottom: $scale2;
 }

 .itemTitle{
   font-size: 1em;
   font-weight: 600;
   margin-bottom: 8px;
 }

 .itemSubTitle{
   font-size: 0.875em;
   color:$c-gray-light;
 }

 .errorMessage {
    color:$c-red;
    font-size: 0.875em;
 }
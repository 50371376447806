@import "components/global.scss";

.pdfRenderer {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: $scale3;
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  margin-right: $scale;
}
.buttonNav {
  margin-bottom: $scale;
}

.buttonNavActive {
  color: $c-text;
}

.buttonNavInactive {
  color: $c-gray-light;
}

.pdfViewer {
  canvas {
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 800px;
  }
  filter: drop-shadow(0px 4px 54px rgba(0, 0, 0, 0.10));

  z-index: -1;
}

.pdfViewerSmall {
  canvas {
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 800px;
  }
  border-radius: 5px;
  margin-bottom: $scale;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.08));
  z-index: -1;
  border: 2px solid $c-gray-very-light;
}

.updateIndicatorWrapper {
  position: absolute;
  top: 60;
  right: 30px;
  z-index: 900;
  display: flex;
  justify-content: right;
}

.pdfViewerWrapper {
  width: 520px;
}

.smallPreview {
  margin-bottom: 8px;
}
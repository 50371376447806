@import "components/global.scss";

.editorWrapper {
  display: flex;
  flex-direction: column;
}

h2 {
  //text-transform: uppercase;
}



// block_editor

.dragDropWrapper {
  width: 680px;
  border-radius: $radius;
  margin: 0px $scale3;
  //padding-top: $scale1 0px ;
  height: calc(100vh - #{$scale3} - #{$scale3} - 78px);
  // overflow-y: hidden;
  overflow: visible;
  
}

.editorRecommendations {
  display: flex;
  flex-direction: column;
  padding: $scale1;
  width: 180px;
  background: #fff;
  box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.06);
  height: calc(100vh - 78px);
}

//theme_selector

.themeConfiguration {
  width: 460px;
  border-radius: $radius;
  margin: 0px $scale3;
  //padding: $scale1 0px ;
  height: auto;
  max-height: calc(100vh - #{$scale3} - #{$scale3} - 78px);
  overflow-y: auto;
  //background-color: pink;
  border-radius: $radius;
  background: #fff;
  box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.06);
}

.themePreviewSpace {
  display: flex;
  justify-content: space-around;
  width: calc(100vw - 76.3px);
}

.themePreview {
  width: 770px;

  max-height: calc(100vh - #{$scale3} - #{$scale3} - 78px);
}

.themeNavSelectItem {
  margin-bottom: $scale;
  width: 60px;
}




@import 'components/global.scss';

.main {
  
};

.itemStyle {
    display: flex;
    align-items: baseline;
}

.listItemStyle {
    list-style: circle;
    margin-left : 30px;
    li {
        margin-top: 12px;
    }
}

@import "components/global.scss";

.basicInfoResponsiveHelper {
  height: calc(100vh - 78px);
  display: flex;
  justify-content: center;
  .basicInfoMain {
    display: flex;
    justify-content: center;
    margin-left: auto;

    @include largeDesktop {
      width: calc((100% - 55px) / 12 * 5);
      max-width: 530px;
    }
    @include desktop {
      max-width: 530px;
      width: calc((100% - 55px) / 3 * 2);
    }
  }
  .basicInfoPreview {
    margin: $scale $scale2;
    margin-right: auto;
    // padding: $scale $scale4;
    @include largeDesktop {
      max-width: 530px;
      width: calc((100% - 55px) / 12 * 4);
    }
    @include desktop {
      width: calc((100% - 55px) / 3 * 1);
      max-width: 530px;
    }
  }
}

@import "components/global.scss";

.howItWorks {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: $scale 0px $scale2 0px;
  background-color: #f6f6f6;
  @include tablet {
    flex-direction: column;
    align-items: center;
  }
  @include mobile {
    flex-direction: column;
    align-items: center;
  }

  .selectorElement {
    width: 40vw;
    max-width: 485px;
    margin-bottom: $scale3;
    @include tablet {
      width: 80vw;
    }
    @include mobile {
      width: 96vw;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    button {
      font-family: $font-default;
      color: #313131;
      text-align: center;
      font-size: 26px;
      border-radius: $radius;
      padding: 8px 12px;
      margin: 4px;
      &:hover {
        background-color: $c-gray-very-light;
      }
      @include tablet {
        font-size: 20px;
      }
      @include mobile {
        font-size: 16px;
      }
    }

    .andMoreLabel {
      font-family: $font-default;
      color: #313131;
      text-align: center;
      font-size: 22px;
      border-radius: $radius;
      padding: 8px 12px;
      margin: 4px;
      @include tablet {
        font-size: 16px;
      }
      @include mobile {
        font-size: 12px;
      }
    }
    .sectionTitle {
      font-family: $font-default;
      text-align: center;
      margin-top: $scale;

      color: #313131;
      font-size: 56px;
      font-weight: 700;
      line-height: 60px;
      padding: 0px 12px;
      @include tablet {
        font-size: 30px;
        line-height: 36px;
      }
      @include mobile {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
  .previewElement {
    display: flex;
    background-size: cover;
    justify-content: center;
    width: 42vw;
    height: calc(42vw * 75 / 100);
    .previewGif {
      align-self: center;
      img {
        border-radius: 15px;
      }
      width: calc(42vw * 90 / 100);
    }
    @include tablet {
      width: 80vw;
      height: calc(80vw * 75 / 100);
      .previewGif {
        width: calc(80vw * 90 / 100);
      }
    }
    @include mobile {
      width: 90vw;
      height: calc(90vw * 75 / 100);
      .previewGif {
        width: calc(90vw * 90 / 100);
      }
    }
  }
}

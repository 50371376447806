@import "components/global.scss";

.toggleButtonsGroupWrapper {
  display: flex;
  justify-content: space-around;
  border: 1px solid #f2f2f2;
  border-radius: $radius;
  // background-color: $c-gray-very-light;
  height: 32px;
  overflow: hidden;
}
.toggleButtonsWrapper {
  display: flex;
  justify-content: space-around;
  border-radius: $radius;
  border: 1px solid #f2f2f2;
  height: 32px;
  overflow: hidden;
  margin-right: 8px;
  background-color: #fff;
  button {
    width: 100%;
  }
}
.toggleButtonsGroup {
  height: 100%;
  padding: 0px $scale;
}
.toggleButtons {
  height: 100%;
  padding: 0px $scale;
}
.optionSelected {
  background-color: $c-purple;
  color: #fff;
}
.optionNotSelect {
  color: $c-text;
}
@import 'components/global.scss';

.ThemePicker {
   display: flex;
   justify-content: space-between;
   align-items: center;
   border: 1px solid $c-gray-very-light;
   border-radius: $radius;
   margin: $scale 0px $scale 0px;
   padding: 12px 20px;
   &:hover {
      border: 1px solid darken($c-gray-very-light,10%);
      background-color: #f8f8f8;
      cursor: pointer;
   }
}

.pickerSubtitle {
   border-radius: $radius;
   background-color: $c-gray-very-light;
   padding: 2px;
   font-family: $font-default;
   font-style: normal;
   font-weight: 300;
   font-size: 10px;
   line-height: 0px;
   letter-spacing: 4px;
 }
@import "components/global.scss";

.enlargeButton {
  background-color: $c-gray-light;

    padding: 1em;
    width: 44px;
    height: 40px;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    border-radius: 30px;
    display: flex;
    align-items: center;
    transition: width 0.2s ease-out, background-color 0.2s ease-out;
  }
  
  .text {
    visibility: hidden;
    opacity: 0;
    color: white;
    transition: visibility 0s linear 0.5s, opacity 0.5s linear;
  }
  
  .icon {
    position: absolute;
    right: 1em;
    top: 50%;
    transform: translateY(-50%);
  }
  .iconMissingImage {
    position: absolute;
    height: 20px;
    width: auto;
    right: 0.8em;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .enlargeButton.showText {
    width: 156px;
    background-color: $c-gray-light;
  }
  
  .enlargeButton.showText .text {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
    width: 156px;
  }
  
  .enlargeButton:not(.showText) .text {
    visibility: hidden;
    opacity: 0;
    transition-delay: 0s;
  }
  
  .enlargeButton:not(.showText) {
    width: 44px;
    background-color: $c-gray-light;
  }
  
  .enlargeButton:hover .text {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
    width: 156px;
  }
  .enlargeButton:hover {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
    width: 156px;
  }
  
@import "components/global.scss";

.wrapper {
}

// .screenShotCarousselwrapper {
//     position: absolute;
// }
.screenShotCaroussel {
  // width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  //   background: linear-gradient(to bottom, #FFEC5C 50%, white 50%);
}

.screenMenu {
  display: flex;
  width: 58vw;
  height: 60px;
  justify-content: space-between;
  justify-content: space-between;
  @include tablet {
    width: 80vw;
  }
  @include mobile {
    width: 90vw;
    // flex-direction: column;
    // height: 120px;
    padding: 10px 0px;
    // margin-top: 20px;
    justify-content: space-between;
  }
}

.menuItem {
  font-family: $font-default;
  font-size: 22px;
  font-weight: 500;
  color: #313131;
  @include tablet {
    font-size: 16px;
  }
  @include mobile {
    font-size: 14px;
  }
}

.screenShotWrapper {
  width: 58vw;
  height: calc(58vw * (90 / 144));
  border-radius: 25px;
  img {
    border-radius: 25px;
  }
  @include desktop {
    width: 70vw;
    height: calc(70vw * (90 / 144));
  }
  @include tablet {
    width: 85vw;
    height: calc(85vw * (90 / 144));
    img {
      border-radius: 15px;
    }
  }
  @include mobile {
    width: 90vw;
    height: calc(90vw * (90 / 144));
    img {
      border-radius: 15px;
    }
  }
  background-position: center top;
  background-size: 100% auto;
  filter: drop-shadow(0px 22.3363px 17.869px rgba(0, 0, 0, 0.0248468))
    drop-shadow(0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0047312));
}

.dotted_background {
  position: absolute;
  margin-top: 90px;
  height: calc(
    58vw * (90 / 144) / 2 - 60px
  ); // 30 px is 50% of the height of menu items
  @include desktop {
    height: calc(
      70vw * (90 / 144) / 2 - 60px
    ); // 30 px is 50% of the height of menu items
  }
  @include tablet {
    height: calc(
      85vw * (90 / 144) / 2 - 60px
    ); // 30 px is 50% of the height of menu items
  }
  @include mobile {
    display: none;
  }
  display: flex;
  flex-direction: row;
  justify-content: right;
  img {
    width: 100vw;
    height: auto;
    margin-top: auto;
  }
}

.fadeIn {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.fadeOut {
  animation: fadeOutAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOutAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@import 'components/global.scss';

.blockCompilerToHTML {

}

.applyBar {
    display: flex;
    margin:0px auto 0px auto ;
    padding: 16px;
    // background-color : #f2f2f2;
    width: 960px;
    max-width: 100%;
    bottom:0px;
}

.applyButton {
    margin-left: auto;
    padding: 8px 16px;
    border-radius: $radius;
    background-color: #333;
    color: #fff;
    text-decoration: none;
    &:hover {
       background-color: #3f3f3f;
    } 
}
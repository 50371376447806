@import "components/global.scss";

.planCard {
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: space-between;
  // position: relative;
  cursor: pointer;
  color: $c-text;
  width: 100%;
  margin-bottom: $scale1;
  padding: 4px $scale;
  border: 1px solid $c-border;
  background-color: white;
}

.topSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  input {
    background-color: #ffff !important;
  }
}

.middleSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 12px 0px;
}
.bottomSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  margin: $scale auto;
  p {
    font-size: 14px;
    text-align: center;
  }
}

// curency select

.currencySelect {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: $font-default;
  margin-top: 6px;
  margin-right: 4px;
}

////////// PLAN OPTION SELECTORS

.selectStyleInput {
  color: $c-text;
  width: 104px;
  border: none;
}
.selectStyleDropdown {
  border: none;
  background-color: #fff;
}

.selectStyleRoot {
  background-color: #fff;
}

.selectStyleSelected {
  background-color: #f2f2f2;
  color: $c-text;
}

.selectStyledropdownItems {
  color: $c-text;
  margin-bottom: 4px;
}

.priceLabel {
  padding: 8px 16px;
  @include tablet {
    padding: 8px 12px;
  }
  @include mobile {
    padding: 10px 8px 6px 6px;
  }
  .currency,
  .ammount {
    font-size: 30px;
    font-weight: 600;
    @include tablet {
      font-size: 24px;
    }
    @include mobile {
      font-size: 22px;
    }
  }
  .currencyLabel {
    font-size: 20px;
    @include tablet {
      font-size: 14px;
    }
    @include mobile {
      font-size: 12px;
    }
  }
  .forWhat {
    font-size: 16px;
  }
}

.plusSign {
  margin: $scale1;
  @include tablet {
    margin: $scale;
  }
  @include mobile {
      margin: 4px;
    }
}

.priceLabelSecondary {
  border: 2px dotted $c-text;
  padding: 8px 16px;
  border-radius: $radius;
  color: $c-text;
  @include tablet {
    padding: 8px 12px;
  }
  @include mobile {
    padding: 4px 8px 8px 8px;
  }
  .currency,
  .ammount {
    font-size: 20px;
    font-weight: 600;
    line-height: 0px;
    @include tablet {
      font-size: 18px;
    }
    @include mobile {
      font-size: 16px;
    }
  }
  .currencyLabel {
    font-size: 12px;
    @include tablet {
      font-size: 10px;
    }
    @include mobile {
      font-size: 8px;
    }
  }
  .forWhat {
    font-size: 10px;
  }
  .optionalLabel {
    font-size: 8px;
  }
}

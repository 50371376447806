@use "sass:math";
@import "components/global.scss";

.export_modal {
  position: absolute;
  top: calc(50vh - 360px);  // adjust this value based on the modal's height for better vertical centering
  width: 100%;             // by default, the modal takes the full width

  @media (min-width: 960px) {
    width: 1080px;
    margin-left: calc(50vw - 540px);
  }

  @media (max-width: 959px) { // adjusted to 959px to avoid conflict with the above media query
    width: 920px;
    margin-left: 20px;        // This will left-align the modal on smaller screens
  }

  background-color: $c-bg;
  padding: $scale2;
  border-radius: $radius;

  h2 {
    margin: auto;
    margin-bottom: 20px;
    text-align: center;
  }

  h3 {
    background-color: darken($c-purple,30%);
    border-radius: $radius;
    text-align: center;
    color: #ffffff;
    padding: 4px 12px;
    margin-left: $scale2;
    margin-right: $scale2;
    margin-bottom: $scale;
  }
}

.iconStyle {
  margin-left: 4px;
}

.subtitleSmall {
  margin-bottom: 4px;
  font-weight: 500;
}

.copyLinkWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  .textareaCopyLink {
    width: 220px;
    height: 40px;
    border-radius: $radius;
    padding: 12px 10px;
    margin-right: 12px;
    color: $c-text;

    resize: none;
    white-space: pre;
    overflow-wrap: normal;
    overflow-x: hidden;
  }
}

.twoColumnWrapper {
  display: flex;
  flex-direction: column;
  .mainExport {
    display: flex;
    flex-direction: column;
    padding: 0px 20px 0px 0px;
  }
  .secondarySettings {
    margin-top: $scale;
  }
}

.methodsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.exportMethodWrapper {
  margin: 8px auto;
  width: 30%;
}

.HTMLtextarea {
  width: 100%;
  height: 160px;
  border-radius: $radius;
  padding: 12px 10px;
  margin-right: 12px;
  margin-bottom: 12px;
  color: $c-text;

  font-size: 0.875em;
  &::spelling-error {
    color: aquamarine;
  }

  resize: none;
  // white-space: pre;
  // overflow-wrap: normal;
  overflow-y: scroll;
}

.switchButton {
  height: 22px;
  width: 45px;
  background-color: $c-green;
  border-radius: 11px;
  padding: 3px;
  margin-bottom: $scale;
  .switchIndicator {
    height: 16px;
    width: 16px;
    border-radius: 10px;
    background-color: white;
    margin-left: 23px;
    transition: margin-left 0.2s ease-in-out;
  }
  &.switchButtonActive {
    background-color: lighten($c-gray-light, 20%);
    .switchIndicator {
      background-color: white;
      margin-left: 0px;
    }
  }
}

.CopyButton {
  height: 30px;
  background-color: $c-purple;
  color: white;
  padding: 8px 12px;
  border-radius: $radius;
  font-size: 0.75em;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: lighten($c-purple, 10%);
  }
}

//https://github.com/atomiks/tippyjs-react/issues/52

.clickToCopyText {
  border: 2px solid #f2f2f2;
  padding: 4px;
  border-radius: $radius;
  width: 100px;
  &:hover {
    //background-color: #f2f2f2;
    color: #cbe5c4;
    cursor: pointer;
    //background-color: $c-bg;
  }
  .tooltipNormal {
    background-color: pink;
  }
  .tooltipAfterClick {
    background-color: blue;
  }
}

.text_highlight {
  color: pink;
}

.errorMessage {
  color: red;
  font-size: 12px;
}

.inputSelect {
  input {
    border: 1px solid #f2f2f2;
    padding: 8px 16px;
    margin-bottom: 4px;
    color: $c-text;
  }
  border-radius: $radius;
  height: 34px;
}

.smallSubtitle {
  font-size: 0.875em;
  margin: 4px 8px;
}
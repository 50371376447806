@import "components/global.scss";

.hero {
  width: 100%;
  background-color: #FFEC5C;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: $scale;
  
  h1,h3,h4,h5,p,button,a {
    font-family: $font-default;
  }

    h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 80px;
    line-height: 97px;
    text-align: center;
    color: #313131;
    @include tablet {
      font-size: 50px;
      line-height: 56px;
    }
    @include mobile {
      font-size: 50px;
      line-height: 56px;
    }
    margin-bottom: 10px;
  }

  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    @include tablet {
      font-size: 30px;
      line-height: 36px;
    }
    @include mobile {
      font-size: 18px;
      line-height: 22px;
    }
    color: #313131;
  }

  .mainCTAButton {
    background-color: #313131;
    color: white;
    font-size: 30px;
    font-weight: 500;
    @include tablet {
      font-size: 20px;
    }
    @include mobile {
      font-size: 18px;
    }
    padding: 12px 12px;
    width: 294px;
    max-width: 100%;

    border-radius: 60px;
  }

  p {
    text-align: center;
    @include mobile {
      font-size: 14px;
    }
  }

  .headerHighlight {
    background-color: rgba(0, 0, 0, 0.1);  }
}

@import 'components/global.scss';

.modalWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalMain {
  width: 800px;
  height: 500px;
  position: absolute;

  text-align: center;

  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-around;

  left: 0;
  right: 0;
  top: calc(50vh - 250px);
  // top: calc(50vh - 200px);
  // max-width: 100%;
  // margin: auto calc(50vw - 200px);

  background-color: $c-bg;
  padding: $scale2;
  border-radius: $radius;


  &:focus {
    outline: none;
  }
  h2 {
    margin-bottom: 0px;
  }
}

.templateOptionsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

}

.templateOption {
  display: flex;
  padding: $scale3 $scale;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  border-radius: $radius;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.06);
  width: 350px;
  height: 290px;
  background-color: white;

  button {
    width: 120px;
    margin: 0px auto;
  }
  h3 {
    margin-top: 0px;
    text-align: center;
  }

  .imageDescriptionWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .description {
      flex-grow: 1;
      margin-left: 12px;
    }

    .templateImg {
      background-color: #e7e7e7;
      padding: $scale $scale1;
      margin-right: 8px;
      border-radius: 4px;
      svg {
        height: 80px;
        width: auto;

      }
    }
  }

}
@import "components/global.scss";

.clientReferalEditorial {
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    width: 100%;
  }
}

.middle {
  background-color: #ffec5c;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .sectionTitle,
  .name,
  p,
  .company {
    font-family: $font-default;
    color: #313131;
    text-align: center;
  }

  .sectionTitle {
    font-size: 56px;
    font-weight: 700;
    line-height: 60px;
    margin-top: $scale1;
    margin-bottom: $scale1;
    @include tablet {
      font-size: 30px;
      line-height: 34px;
    }
    @include mobile {
      font-size: 20px;
      line-height: 24px;
    }
  }

  .clients {
    display: flex;
    flex-direction: row;
    @include tablet {
      flex-direction: column;
    }
    @include mobile {
      flex-direction: column;
    }
    justify-content: space-around;
    align-items: center;
    margin-bottom: $scale2;
    p {
      font-weight: 500;
      font-size: 26px;
      line-height: 28px;
      font-weight: 300;

      @include tablet {
        font-size: 20px;
        line-height: 24px;
        font-weight: 400;
      }
      @include mobile {
        font-size: 16px;
        line-height: 18px;
        font-weight: 400;
      }
    }
    .client {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 40vw;
      @include tablet {
        width: 70vw;
      }
      @include mobile {
        width: 86vw;
      }
      svg,
      img {
        width: 120px;
        margin-bottom: $scale;
      }
      .name,
      .company {
        font-family: $font-default;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 0px;
        @include tablet {
          font-size: 12px;
          line-height: 14px;
          font-weight: 400;
        }
        @include mobile {
          font-size: 10px;
          line-height: 12px;
          font-weight: 400;
        }
      }
      @include tablet {
        margin-bottom: $scale6;
      }
      @include mobile {
        margin-bottom: $scale6;
      }
    }
  }
}

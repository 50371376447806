@import 'components/global.scss';

.AddBlockPlaceholder {
  width: 100%;
  height: $scale8;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $radius;
  border: 1px dashed $c-gray-light;
  background-color: $c-gray-very-light;
}

.contextCardWrapper {
  margin: $scale2;
  height: calc(100vh - 78px);
  padding-bottom: $scale4;
}
.contentWrapper {
  margin: $scale1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.closeButton {
  margin: 18px 20px;
  background-color: $c-purple;
}
@import "components/global.scss";

.themeEditorResponsiveHelper {
  height: calc(100vh - 78px);
  display: flex;
  .MainArea {
    flex-grow: 1;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 90px 1fr 390px;
    grid-template-areas: 
    "themeModeSelector previewSpace settingsSpace";
    .themeModeSelector {
      grid-area: "themeModeSelector";
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: $scale 0px;
      box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.06);
      background-color: white;
    }
    .previewSpace{
      grid-area: "previewSpace";
      // margin: 0px $scale4;
      width: 100%;
      // max-width: 750px;
      // @include largeDesktop {
        // margin: 0px auto;
      // }
      display: flex;
      justify-content: center;
      flex-grow: 1;
      height: 100%;
      overflow: hidden;
    }
    .settingsSpace{
      background-color: white;
      grid-area: "settingsSpace";
      height: 100%;

        display: flex;
        flex-direction: column;
        //padding: $scale2;
        // width: 480px;
        box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.06);
        height: calc(100vh - 78px);
      
    }
  }
}

.iconWrapper {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  margin-bottom: 4px;
  background-color: $c-blue;
}

.inButtonIcon{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 60px;
}

.buttomWrapper {
  margin-bottom: $scale;
}

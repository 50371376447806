@import "components/global.scss";
.textOnImageForm {
  width: 100%;
  h5 {
    margin-top: $scale;
  }
}
.bannerTextForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .bannerTextOption {
    display: flex;
    flex-direction: column;
    margin-left: $scale;
    margin-bottom: 12px;
    font-size: 0.875em;
    display: flex;
    flex-direction: column;
    span {
      margin-bottom: 4px;
    }
    input {
      margin-left: $scale-1;
    }
  }
}

.switchButton {
  height: 22px;
  width: 45px;
  background-color: $c-green;
  border-radius: 11px;
  padding: 3px;
  margin-bottom: $scale;
  .switchIndicator {
    height: 16px;
    width: 16px;
    border-radius: 10px;
    background-color: white;
    margin-left: 23px;
    transition: margin-left 0.2s ease-in-out;
  }
  &.switchButtonActive {
    background-color: lighten($c-gray-light, 20%);
    .switchIndicator {
      background-color: white;
      margin-left: 0px;
    }
  }
}

.arrowWrapper {
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }
}

@import "components/global.scss";
:global {
  .tippy-box[data-theme~="lightbackground"] {
    // background-color: $c-gray-very-light;
    color: $c-text;
    border-radius: 8px;
  }
}

.wrapperStyle {
  font-family: $font-default;
  color: $c-text;
  border: 1px solid #f2f2f2;
  background-color: #f2f2f2;;
  padding: 4px 8px;
  border-radius: $radius;

}

.tippyWrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.hexInput {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  input {
    // background-color: $c-gray-very-light;
    height: 12px;
  }
}

.colorIndicator {
  height: 20px;
  width: 20px;
  border: 1px solid #aaaaaa;
  border-radius: 50%;
  margin-left: 8px;
}

.toppyPopInStyle {
  padding: $scale;
}
@import "components/global.scss";

.myButton {
  display: flex;
  align-items: center;
  justify-content: center;

  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;


 
  font-family: $font-default;

  &:hover {
    background-color: darken($c-gray-very-light, 5%);
  }

  .img_wrapper {
    margin: 0px 4px;
    .icon {
      height: 12px;
      width: auto;
    }

    .img {
      height: 12px;
      width: auto;
    }

  }
}

.loaderIcon {
  position: absolute;
  animation: rotation 8s infinite linear;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
}

.displayDuringLoad {
  visibility: hidden;
}

// Sizes

.mySmallButton {
  padding: 4px 8px;

  font-size: 0.75em;

  width: auto;
  max-width: 100%;
  border-radius: $radius;

  .img_wrapper {
    margin: 0px 8px;
    .icon {
      height: 12px;
      width: auto;
    }

    .img {
      height: 12px;
      width: auto;
    }
  }
  &.iconButton {
    .img_wrapper {
      margin: 0px 12px;
      .icon {
        height: 14px;
        width: auto;
      }
  
      .img {
        height: 14px;
        width: auto;
      }
    }
  }
}

.myMediumButton {
  padding: 8px 14px;

  font-size: 0.875em;

  width: auto;
  max-width: 100%;
  border-radius: $radius;
  .img_wrapper {
    margin: 0px 8px;
    .icon {
      height: 14px;
      width: auto;
    }

    .img {
      height: 14px;
      width: auto;
    }
  }
  &.iconButton {
    .img_wrapper {
      margin: 0px 12px;
      .icon {
        height: 16px;
        width: auto;
      }
  
      .img {
        height: 16px;
        width: auto;
      }
    }
  }
}

.myLargeButton {
  padding: 12px 20px;

  font-size: 1.125em;

  width: auto;
  max-width: 100%;
  border-radius: $radius;
  .img_wrapper {
    margin: 0px 12px;
    .icon {
      height: 20px;
      width: auto;
    }

    .img {
      height: 20px;
      width: auto;
    }
  }
  &.iconButton {
    .img_wrapper {
      margin: 0px 12px;
      .icon {
        height: 20px;
        width: auto;
      }
  
      .img {
        height: 20px;
        width: auto;
      }
    }
  }
}

//Display as block

.myBlockButton {
  width: 100%;
}

//styles

.myPrimaryButton {
  background-color: $c-purple;
  color: white;
  &:hover {
    background-color: darken($c-purple, 5%);
  }
}

.mySecondaryButton {
  color: $c-text;
  background-color: $c-gray-very-light;
  &:hover {
    background-color: darken($c-gray-very-light, 5%);
  }
}

.myAlternativeButton {
  color: white;
  background-color: $c-blue;
  &:hover {
    background-color: darken($c-gray-very-light, 5%);
  }
}
.myWhiteButton {
  color: $c-purple;
  background-color: white;
  &:hover {
    background-color: darken($c-gray-very-light, 5%);
  }
}

.myDangerButton {
  background-color: $c-red;
  color: white;
  &:hover {
    background-color: darken($c-red, 5%);
  }
}

.myOutlineButton {
  border: 1px solid $c-gray-light;
  color: $c-text;
  &:hover {
    background-color: lighten($c-gray-very-light, 0%);
  }
}

.myDisabledButton {
  cursor: not-allowed;
  background-color: $c-gray-very-light;
  color: lighten($c-text,20%);
  &:hover {
    background-color: $c-gray-very-light;
  }
  svg {
    path {
      fill : lighten($c-text,20%);

    }
  }
}

.myGhostButton {
  color: $c-text;
  &:hover {
    background-color: lighten($c-gray-very-light, 0%);
  }
}
.myGhostButton.active {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.active {
  border-bottom: 3px solid lighten($c-purple, 30%);
  border-top: 3px solid transparent;
  ;
}



.loading {
  margin: 0px 24px;
  svg {
    height: 12px;
    width: auto;
    animation: spin 8s linear infinite; /* Apply the animation here */
  }
}

/* Define the keyframes for the rotation */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
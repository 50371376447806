@import "components/global.scss";

.jobBasicInfoPreview {
  box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.06);
  padding: $scale1 $scale2;
  border-radius: $radius;
  border: 1px solid $c-gray-very-light;
  flex-wrap: wrap;
  h3 {

  }
  .jobIndicators {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .singleItem {
      margin-right: $scale2;
      margin-bottom: 8px;
    }
    svg {
      margin: 0px 6px 0px 0px;
    }
  }
}

@import "components/global.scss";

// Grid layout templates
$grid-template: 3fr 2.2fr 1.4fr 96px 230px;

// Job card individual elements
.j_name {
  grid-area: j_name;
}
.j_category {
  grid-area: j_category;
}
.j_dateCreated {
  grid-area: j_dateCreated;
}
.j_statusSelector {
  grid-area: j_statusSelector;
}
.actionButtonsWrapper {
  grid-area: actionButtonsWrapper;
}

// Job card component
.jobCard {
  display: grid;
  grid-template-columns: $grid-template;
  grid-template-areas: "j_name j_category j_dateCreated j_statusSelector actionButtonsWrapper";
  align-items: center;
  border-radius: $radius;
  border: 1px solid $c-gray-very-light;
  margin-bottom: $scale;
  padding: $scale-2;

  .actionButtonsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: right;
    button {
      margin: 0px 3px;
    }
  }

  h4,
  p {
    margin: 0px $scale 0px 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 0.875em;
  }

  // // Responsive design for tablet
  // @media screen and (max-width: 1200px) and (min-width: 421px) {
  //   width: 100%;
  //   grid-template-columns: 1fr 100px;
  //   grid-template-rows: 30px 20px 20px 60px;
  //   grid-template-areas:
  //     "j_name             actionButtonsWrapper"
  //     "j_category         actionButtonsWrapper"
  //     "j_dateCreated      actionButtonsWrapper"
  //     "j_statusSelector   actionButtonsWrapper";

  //   .j_name {
  //     text-align: left;
  //   }
  //   .j_statusSelector {
  //     justify-self: start;
  //     align-self: flex-end;
  //     padding: 12px;
  //   }
  //   .actionButtonsWrapper {
  //     display: flex;
  //     justify-self: center;
  //     // flex-direction: column-reverse;
  //     justify-self: flex-end;
  //     justify-content: space-around;
  //     button {
  //       margin: 2px 0px;
  //       width: 92px;
  //     }
  //   }
  // }

    // Tablet layout
    @media screen and (max-width: 1200px) and (min-width: 901px) {
      grid-template-columns: 2fr 2fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-template-areas: 
      "j_name     j_name        j_statusSelector actionButtonsWrapper"
      "j_category j_dateCreated j_statusSelector actionButtonsWrapper";
      .menuItem {
        justify-content: center;
      }  
      .j_name {
        justify-content: center;
      }
      .j_category {
        justify-content: center;
      }
      .j_dateCreated {
        justify-content: center;
      }
      .j_statusSelector {
        justify-content: center;
      }
      .actionButtonsWrapper {
        justify-content: center;
      }
    }
    // Tablet layout

    @media screen and (max-width: 900px) and (min-width: 701px) {
      grid-template-columns: 2fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-areas: 
      "j_name             j_statusSelector actionButtonsWrapper"
      "j_category  j_statusSelector actionButtonsWrapper"
      "j_dateCreated  j_statusSelector actionButtonsWrapper";
      .menuItem {
        justify-content: center;
      }  
      .j_name {
        justify-content: center;
      }
      .j_category {
        justify-content: center;
      }
      .j_dateCreated {
        justify-content: center;
      }
      .j_statusSelector {
        justify-content: center;
      }
      .actionButtonsWrapper {
        justify-content: center;
      }
    }

    @media screen and (max-width: 700px) and (min-width: 420px) {
      grid-template-columns: 2fr 96px;
      grid-template-rows: 1fr 1fr 1fr 1.71fr;
      grid-template-areas: 
      "j_name              actionButtonsWrapper"
      "j_category   actionButtonsWrapper"
      "j_dateCreated   actionButtonsWrapper"
      "j_statusSelector   actionButtonsWrapper";
      .menuItem {
        justify-content: center;
      }  
      .j_name {
        justify-content: center;
      }
      .j_category {
        justify-content: center;
      }
      .j_dateCreated {
        justify-content: center;
      }
      .j_statusSelector {
        justify-content: center;
        margin-left: 12px;
      }
      .actionButtonsWrapper {
        justify-content: center;
        flex-direction: column;
        button {
          margin: 2px 0px;
        }
      }
    }

  // Responsive design for mobile
  @media screen and (max-width: 420px) {
    grid-template-columns: 1fr 96px;
    grid-template-rows: 30px 30px 40px 40px;
    grid-template-areas:
      "j_name             j_name"
      "j_category         j_category"
      "j_dateCreated      j_statusSelector"
      "actionButtonsWrapper   actionButtonsWrapper";

    .j_name {
      text-align: left;
    }
    .j_category,
    .j_dateCreated {
      text-align: left;
    }
    .j_statusSelector,
    .actionButtonsWrapper {
      justify-self: center;
    }
    .actionButtonsWrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      button {
        margin: 0px 2px;
      }
    }
  }
}

.editButton {
  margin: 0px $scale 0px auto;
}

// Job card menu component
.jobCardMenu {
  padding: $scale-2;
  margin: $scale 0px $scale-4;
  display: grid;
  grid-template-columns: $grid-template;
  justify-content: left;
  // Menu item styles
  .menuItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;

    .itemWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 8px 8px 12px;
      border-radius: $radius;

      &:hover {
        cursor: pointer;
        background-color: $c-gray-very-light;
      }

      .menuItemLabel {
        font-size: 0.875em;
        color: #313131;
        margin-right: 8px;
      }
    }

    // Selected menu item style
    .menuItemStyleSelected {
      background-color: $c-gray-very-light;
      &:hover {
        background-color: lighten($c-gray-very-light, 2%);
      }
    }

    // Sort group styles
    .sortGroup {
      display: flex;
      flex-direction: column;
      .sortIcon {
        height: 8px;
        width: 8px;
        margin: 0 px 4px;
        path {
          fill: darken($c-gray-very-light, 30%);
        }
        &.arrowStyleActive {
          path {
            fill: $c-purple;
          }
        }
        &.arrowStyleInactive {
          path {
            fill: darken($c-gray-very-light, 20%);
          }
        }
      }
    }

    .filterIcon {
      margin-right: 8px;
    }
  }

  // Tablet layout
  @media screen and (max-width: 1200px) and (min-width: 601px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    .menuItem {
      justify-content: center;
      }
    .menuItem {
      justify-content: center;
    }  
    .j_name {
      justify-content: center;
    }
    .j_category {
      justify-content: center;
    }
    .j_dateCreated {
      justify-content: center;
    }
    .j_statusSelector {
      justify-content: center;
    }
    .actionButtonsWrapper {
      justify-content: center;
    }
  }


  // Mobile layout
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 40px 40px;
  }


}

.jobStatusSelector {
  padding: 4px 8px;
  border-radius: 0.25em;
  background-color: #f2f2f2;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  height: 100%;
  text-align-last: center;
  color: $c-text;
  border: 3px solid #f2f2f2;
  width: 92px;
}

.iconSize {
  margin-left: 4px;
}

.modalMain {
  width: 360px;
  height: 200px;
  position: absolute;

  text-align: center;

  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-around;

  left: 0;
  right: 0;
  top: calc(50vh - 100px);

  background-color: $c-bg;
  padding: $scale2;
  border-radius: $radius;

  &:focus {
    outline: none;
  }

  .buttonWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .cancelButton {
      margin-right: 10px;
    }
  }
}

.tooltipfilter {
  display: flex;
  flex-direction: column;
  padding: 4px;

  label {
    margin-bottom: 4px;
    input {
      margin-right: 6px;
    }
  }
}

//duplicate job modal

.delete_modal {
  background-color: white;
  position: absolute;
  top: calc(50vh - 270px);
  width: 400px;
  max-width: 100%;
  margin: auto calc(50vw - 200px);
  padding: $scale2;
  border-radius: $radius;
}

.flexDivHorizontal {
  display: flex;
  flex-direction: row-reverse;
}

.addBlockButtonButtom {
  display: flex;
  justify-content: center;
  margin-top: $scale5;
}

.buttonsStyle {
  margin-left: $scale;
}

.buttonsIconStyle {
  margin-left: 4px;
}

.formStyle {
  display: flex;
  flex-direction: column;
}

.inputWrapper {
  input,
  select {
    background-color: $c-gray-very-light;
    margin: 4px 0px;
    padding: 12px 4px;
    width: 100%;
    border-radius: $radius;
    color: $c-text;
  }
  margin-bottom: $scale2;
}

.itemTitle {
  font-size: 1em;
  font-weight: 600;
  margin-bottom: 8px;
}

.itemSubTitle {
  font-size: 0.875em;
  color: $c-gray-light;
}

.errorMessage {
  color: $c-red;
  font-size: 0.875em;
}

.jobBeingCopied {
  // background-color: lighten($c-gray-very-light, 50%);
  border-radius: $radius;
  border: 1px solid $c-gray-light;
  margin: 12px 18px;
  padding: 8px 12px;
  text-align: center;
  .introText {
    margin-bottom: 0px;
  }
  .jobName {
    font-weight: bold;
  }
}

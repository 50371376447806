@import "components/global.scss";
.addImageState {
  .subtitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    // margin: $scale1 0px;
    h3 {
      margin-bottom: 0px !important;
    }
    svg {
      height: 16px;
      width: auto;
      margin-right: $scale;
      float: left;
    }
  }

  .yellow {
    path {
      fill:  $c-text;
    }
  }
}

.infoTextLineBox {
    border-radius: $radius;
    border: 3px solid #f9f9f9;
    margin: 0px auto $scale auto;
    padding: 8px 12px;
    width: 90%;
  .textLine {
    font-family: $font-default;
    font-size: 0.875em;
    text-align: center;
    color: $c-text;
    i {
        color: $c-purple;
    }
  }
}
